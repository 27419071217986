import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled(Box)`
    background-color: #596d7a1a;
    padding: 1rem;
    margin-top: 2rem;
`;

interface IProps {
    last: string;
}

export const Link = styled(NavLink)<IProps>`
    text-decoration: none;
    font-size: 14px;
    font-weight: ${props => props.last ? 'Bold' : ''};
    &:hover {
        text-decoration: underline;
    },
    &.visited {
        color: inherit;
    }
`;

export const Span = styled.span<IProps>`
    font-size: 14px;
    font-weight: ${props => props.last ? 'Bold' : ''};
`