import { Box } from "@mui/material";

interface IProps {
    error: any
}

const Error: React.FC<IProps> = () => 
    <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red'}}>
        <span>Une erreur est survenue, veuillez contacter le support si l'erreur persiste...</span>
    </Box>

export default Error;