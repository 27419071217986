import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { CERTIFICATION_MANDATORY_COLUMNS_HEADER } from "../../../lib/referentiel";

interface IProps {
    columns: string[];
    header: string[]
    rows: Record<string, string>[];
}

const Component: React.FC<IProps> = ({header, columns, rows}) => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mt: '2rem' }}>
                <TableContainer sx={{maxWidth: '100%', p: 1}}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {header.map((column) => (
                                    <TableCell style={{borderRightStyle: 'groove'}} key={column}><strong>{column}</strong></TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((r, index) => (
                                <TableRow  key={index}>
                                    {columns?.map((col) => (
                                        <TableCell sx={{borderRightStyle: 'groove', fontSize: '12px'}} key={`cell_${col}_${index}`}>{r[col]}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={({count, from, to}) => <span>{from}-{to} de {count}</span>}
                    labelRowsPerPage={'Lignes par page'}
                />
            </Paper>
        </Box>
    )
}

export default Component;