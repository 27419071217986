import { shallowEqual, useSelector } from 'react-redux';
import { TemplatesTable } from '../components/TemplatesTable';
import { templeDataMapper } from '../lib/Template';
import { IState } from '../lib/store';
import { Box, CircularProgress } from '@mui/material';


export const Templates: React.FC = () => {
    const loading = useSelector((state: IState) => state?.organization?.loading);
    const templates = useSelector((state: IState) => state?.organization?.orgs[0]?.templates, shallowEqual)!;

    return (
        <>
            {
                loading ? (
                    <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
                ) :  (
                    <TemplatesTable rows={templeDataMapper(templates || [])} />
                )
            }
        </>
    );
}