import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CertFromCsv from "../components/CertFromCsv";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { IInput } from "../entities/certificates";
import { VERIFICATION_MANDATORY_COLUMNS, VERIFICATION_MANDATORY_COLUMNS_HEADER } from "../lib/referentiel";
import { AppDispatch } from "../lib/store";
import { createBatchPreCertificates } from "../lib/store/projects/thunks";
import { initBatchVerificationSession } from "../lib/store/verification/thunks";

export default () => {
    const navigate = useNavigate();

    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
    const [batchInputs, setBatchInputs] = useState<IInput[]>();

    const verifyBatch = (inputs: IInput[]) => {
        if (inputs && inputs.length == 0) {
            toast.error("données corrompues");
            return;
        }
        setLoading(true);
        dispatch(initBatchVerificationSession(inputs)).unwrap()
            .then(() => toast.success('Session créée.'))
            .then(() => navigate(-1))
            .catch(() => toast.error('Une erreur est survenue lors de l\'initialisation de la session, veuillez contacter le support si cela persiste...'))
            .finally(() => {
                setLoading(false);
                setOpenConfirmationDialog(false);
            });
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            height="100%"
            className="color-chart"
            gap={2}
        >
            <Box display="flex" gap={2} className="color-chart">
                <LoadingButton onClick={() => setOpenConfirmationDialog(true)} size="small" loading={loading} variant="outlined" color="inherit" type="submit">Vérifier</LoadingButton>
            </Box>
 
            <Box height="100%">
                <CertFromCsv mandatory={{columns: VERIFICATION_MANDATORY_COLUMNS, header: VERIFICATION_MANDATORY_COLUMNS_HEADER}} setInputs={setBatchInputs} />
            </Box>

            <ConfirmationDialog open={openConfirmationDialog} close={() => setOpenConfirmationDialog(false)} actionLabel="Pré-VERIFICATION" loading={loading}  action={() => verifyBatch(batchInputs!)}/>
        </Box>
    )
}