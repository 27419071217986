import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SettingsIcon from '@mui/icons-material/Settings';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from "react";
import { MenuItem } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import AdminComponent from '../RolesComponent/Admin';

export default () => {
    const location = useLocation();
    const [path, setPath] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        setPath(location.pathname);
    },[location]);

    return (
        <>
            {
                path.includes('universities')  ? (
                    <>
                        <MenuItem icon={<SchoolIcon />} onClick={() => navigate('/universities')} active={['universities', 'new-organization'].includes(path.split('/').pop()!)}>
                            Universités
                        </MenuItem>
                        <AdminComponent>
                            <MenuItem icon={<FindInPageIcon />} onClick={() => navigate('universities/check-certificates')} active={path.split('/').pop() == 'check-certificates'}>
                                Verification
                            </MenuItem>
                        </AdminComponent>
                        <AdminComponent>
                            <MenuItem icon={<SearchIcon />} onClick={() => navigate('universities/search')} active={path.split('/').pop() == 'search'}>
                                Recherche
                            </MenuItem>
                        </AdminComponent>
                        <AdminComponent>
                            <MenuItem icon={<GroupIcon />} onClick={() => navigate('universities/roles')} active={path.split('/').pop() == 'roles'}>
                                Rôles et autorisations
                            </MenuItem>
                        </AdminComponent>
                        <AdminComponent>
                            <MenuItem icon={<TextSnippetIcon />} onClick={() => navigate('universities/templates')} active={path.split('/').pop() === 'templates'}>
                                Templates
                            </MenuItem>
                        </AdminComponent>
                        <AdminComponent>
                            <MenuItem icon={<SettingsIcon />} onClick={() => navigate('universities/config')} active={path.split('/').pop() == 'config'}>
                                Configuration
                            </MenuItem>
                        </AdminComponent>
                    </>
                ) : (
                    <>
                        <MenuItem icon={<SchoolIcon />} onClick={() => navigate('certifications')} active={path.includes('certifications') && !path.includes('config')}>
                            Diplômes
                        </MenuItem>
                        <MenuItem icon={<TextSnippetIcon />} onClick={() => navigate('dashboard')} active={path.includes('dashboard')}>
                            Dashboard
                        </MenuItem>
                        <MenuItem icon={<SettingsIcon />} onClick={() => navigate('certifications/config')} active={path.includes('certifications/config')}>
                            Configuration
                        </MenuItem>
                    </>
                    
                )
            }

        </>
    )
}