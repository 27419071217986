import { createSlice } from '@reduxjs/toolkit';
import { getTemplateFromId } from '../../Template';
import { IOrganization, ITemplate, IVerificationRequest } from './../../../entities/organization/index';
import { IProfile } from './../../../entities/profile/index';
import { createNewOrganization, createNewTemplate, getOrganizations, getVerificationRequests, inviteMembers, processVrequest, resetOrganization, setOrganization, updateOrg, updateTemplate } from './thunks';

export interface IOrganizationState {
  orgs: IOrganization[];
	organization: IOrganization;
	loading: boolean;
  invitationLoading: boolean;
  members: IProfile[];
	error: any;
}

const initialState = { 
  loading: false,
  orgs: [] as IOrganization[] ,
  invitationLoading: false,
} as IOrganizationState;

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
	},
  extraReducers: (builder) => {
    builder
      .addCase(createNewOrganization.pending, (state, action) => {state.loading = true})
      .addCase(createNewOrganization.fulfilled, (state, action) => {
				state.loading = false;
      })
      .addCase(createNewOrganization.rejected, (state, action) => {
				state.loading = false;
        state.error = action.payload;
      })

			.addCase(getOrganizations.pending, (state, _) => {state.loading = true})
      .addCase(getOrganizations.fulfilled, (state, action) => {
				state.loading = false;
        state.orgs = action.payload as IOrganization[];
        const members: IProfile[] = state.orgs.reduce((acc, curr) => [...acc, ...(curr.members || [])], [] as IProfile[]).map((m, _, mbs) => {
          const mb = mbs.filter((el) => el.email === m.email && el.joinAt !== "");
          if (mb.length > 0) {
            return mb[0];
          }
          return m;
        });

        const mbs = [] as IProfile[];
        members.map((m) => {
          if (mbs.findIndex((mb) => mb.email === m.email && mb.joinAt === m.joinAt) === -1) {
            mbs.push(m);
          }
        });

        state.members = mbs;

      })
      .addCase(getOrganizations.rejected, (state, action) => {
				state.loading = false;
        state.error = action.payload;
      })

      .addCase(setOrganization.pending, (state, _) => {state.loading = true})
      .addCase(setOrganization.fulfilled, (state, action) => {
				state.loading = false;
        state.organization = action.payload as IOrganization;
      })
      .addCase(setOrganization.rejected, (state, action) => {
				state.loading = false;
        state.error = action.payload;
      })

    
      .addCase(inviteMembers.pending, (state, action) => {state.invitationLoading = true})
      .addCase(inviteMembers.fulfilled, (state, action) => {
				state.invitationLoading = false;
        console.log('fulfilled invitemembb', action.payload)
        state.orgs[0] = {
          ...state.orgs[0],
          members: [
            ...(state.orgs[0].members || []),
            ...(action.payload as IProfile[])
          ]
        }

        state.orgs = [...state.orgs];
        state.members = [...state.members, ...action.payload]

      })
      .addCase(inviteMembers.rejected, (state, action) => {
        console.log('errr', action)

				state.invitationLoading = false;
        state.error = action.payload;
      })

      .addCase(createNewTemplate.fulfilled, (state, action) => {
        state.orgs[0].templates = [...(state.orgs[0].templates || []), action.payload as ITemplate];
      })
      .addCase(createNewTemplate.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(updateTemplate.fulfilled, (state, action) => {
        // todo: refacto
        const tp = getTemplateFromId(action.payload.id!, state.orgs[0].templates || []);
        tp!.name = action.payload.name;
        tp!.template = action.payload.template;
        tp!.updatedAt = action.payload.updatedAt;
      })
      .addCase(updateTemplate.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(getVerificationRequests.fulfilled, (state, action) => {
        state.organization.verificationRequests = action.payload;
      })
      .addCase(getVerificationRequests.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(processVrequest.fulfilled, (state, action) => {
        const idx = getVreqIndex(action.payload.id, state.organization.verificationRequests!);
        state.organization.verificationRequests![idx] = action.payload;
      })
      .addCase(processVrequest.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(resetOrganization.fulfilled, (state, _) => {
        state.organization = undefined as any;
      })

      .addCase(updateOrg.fulfilled, (state, action) => {
        const orgs = state.orgs;
        const idx = getOrgIndex(action.payload.id!, orgs);
        orgs[idx].name = action.payload.name;
        orgs[idx].logo = action.payload.logo;

        state.organization.name = action.payload.name;
        state.organization.logo = action.payload.logo;

        state.orgs = [...orgs];
      })

  },
})

export const getOrgIndex = (id: string, orgs: IOrganization[]) => orgs.findIndex((o) => o.id === id) || 0;

export const getVreqIndex = (id: string, vReqs: IVerificationRequest[]): number => {
  return vReqs.findIndex((vr) => vr.id === id) || 0
}

export default organizationSlice.reducer;
