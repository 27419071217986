import { alpha, Typography, Tooltip, IconButton, Button, useMediaQuery } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';
interface IProps {
  openInvitationModal: () => void;  
}

export default (props: IProps) => {
  const matches = useMediaQuery('(min-width:800px)');

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between'
        }}
        className="color-chart"
      >
        <Typography
            // sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Membres
        </Typography>
        <Button onClick={props.openInvitationModal} variant="outlined" color="inherit"><AddIcon /> {matches ? 'Inviter nouveaux membres' : ''}</Button>
      </Toolbar>
    );
}