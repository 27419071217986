import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import certificateLog from "../assets/images/diploma_img.png";
import { TextField, Typography, useMediaQuery } from "@mui/material";
import { SplittedView } from "../components/SplittedLayout";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../lib/store";
import { signIn } from "../lib/store/auth/thunks";
import { Link, useNavigate } from "react-router-dom";
import armoirie from "../assets/images/armoirie.png";

const mdpValidationRule = {
    required: {
        value: true,
        message: 'mot de passe obligatoire'
    },
    // minLength: {
    //     // value: 8,
    //     message: 'le mot de passe doit avoir 8 caractères au moins',
    // },
}

interface IProps {
    loading: boolean; 
    signup: () => void;
    submit: (data: any) => void;
    error: string | undefined;
}

const Right: React.FC<IProps> = ({submit, signup, loading, error}) => {
    const { register, handleSubmit, formState: {errors}, getValues } = useForm();
    const [isSafari, setIsSafari] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:800px)');

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafari)
    }, []);
    return (
        <Box display="flex" flexDirection="column" height="100%" p={5} className="color-chart" width={isSafari ? undefined : matches ? undefined : '100%'}>
            <Box display="flex" justifyContent="space-between" className="register">
                <Typography fontWeight="bold" variant="h4" textAlign="left">Connexion</Typography>
                <img width="20%" src={armoirie} />
            </Box>
            <form onSubmit={handleSubmit(submit)}  style={{display: 'flex', flexDirection: 'column'}}> 
                <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="email"  {...register('email', {
                    required: true,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "adresse email invalide"
                    }
                })}/>
                {errors.email && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>Veuillez entrer une adresse email valide</Typography>}
                <TextField type="password" variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="mot de passe"  {...register('password', {...mdpValidationRule})}/>
                {errors.password && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>{errors.password.message?.toString()}</Typography>}
                <LoadingButton style={{margin: '2rem 0'}} color="inherit" variant="outlined" type="submit" loading={loading}>valider</LoadingButton>

                {error && <Typography color="error" sx={{ textAlign: 'center'}}>{error}</Typography>}
                {error === 'Compte inactif' && <Link to="/reconfirm-account" style={{textDecoration: 'none'}}>Activez votre compte</Link>}
            </form>
        </Box>
    );
}

const Left: React.FC = () => <div style={{display: 'flex', justifyContent: 'center'}}><img src={certificateLog} width="60%" height="100%"/></div>;


export const Login: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const ivtID = params.get('ivtID');
    const matches = useMediaQuery('(min-width:800px)');

    const submit = (data: any) => {
        setLoading(true);
        dispatch(signIn({...data, ivtID})).unwrap()
            .then(() => {console.log('logged'); navigate('/certifications')})
            .catch((err) => setError(err))
            .finally(() => setLoading(false));
    }

    return (
        <div>
            <SplittedView
                Left={Left}
                styleLeft={{padding: matches ? '3rem' : '1rem'}} 
                Right={() => <Right error={error} loading={loading} submit={submit} signup={() => navigate(`/sign-up?ivtID=${ivtID}`)}/>}
                styleRight={{overflow: ''}}
                paperStyle={{backgroundColor: 'white'}}
            />
        </div>
    )
}