import { Controller, useForm } from "react-hook-form";
import { ICertificate } from "../../entities/certificates";
import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, IState } from "../../lib/store";
import { toast } from "react-toastify";
import { updateCertificate } from "../../lib/store/projects/thunks";
import { MAPPING_METADATA_KEYS_TO_LABEL } from "../../lib/referentiel";
import { ROLES } from "../../lib/role-handler";
import { LoadingButton } from "@mui/lab";

interface IProps {
    certificate: ICertificate;
}

const Component: React.FC<IProps> = ({certificate}) => {
    const dispatch = useDispatch<AppDispatch>();

    const { control, handleSubmit, formState } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const currentOrg = useSelector((state: IState) => state.organization.organization, shallowEqual);
    const currentAuth = useSelector((state: IState) => state.auth.profile, shallowEqual);

    const submit = (values: Record<string, string>) => {
        const cert: ICertificate = Object.assign({}, certificate);
        cert.ine = values['ine'];
        cert.grade = values['grade'];
        cert.speciality = values['specialite'];
        cert.metadata = Object.assign({}, certificate.metadata);

        delete cert.metadata['_id'];
        Object.entries(values).filter(([k, v]) => k !== '_id').map(([k, v]) => {
            cert.metadata![k] = v;
        })

        setLoading(true);
        dispatch(updateCertificate(cert)).unwrap()
            .then(() => toast.success('informations mise à jour avec succès'))
            .catch(() => toast.error('Une erreur est survenue pendant l\'opération de mise à jour, veuillez contacter le support si le problème persiste...'))
            .finally(() => setLoading(false));
    }

    const isDisabled = () => {
        if ([ROLES.ADMIN, ROLES.OPERATOR].some((r) => currentAuth.roles?.includes(r))) {
            return false;
        }
        if (certificate.certified) {
            return true;
        }
        return (new Date(currentOrg.importEndDate!).getTime()) >= Date.now();
    }


    return (
        <Box position="relative" component="form" onSubmit={handleSubmit(submit)} className="color-chart" display="flex" flexDirection="column" gap={3}>
                <Typography fontWeight="bold" variant="h5" top={0} position="sticky" display="flex" flexDirection="column" gap={3} mt={2} zIndex={1} bgcolor={'#FFFF'} textAlign="center">
                    <span>Information détaillée</span>
                    {
                        formState.isDirty && (
                            <LoadingButton loading={loading} fullWidth type="submit" variant="outlined" color="inherit" sx={{position: 'sticky', marginBottom: 2}}>Enregistrer</LoadingButton>
                        )
                    }
                </Typography>
            {
                Object.entries(certificate.metadata!).filter(([k, _]) => Object.keys(MAPPING_METADATA_KEYS_TO_LABEL).includes(k)).map(([key, value]) => (
                    <Controller
                        key={key}
                        name={key}
                        control={control}
                        defaultValue={value}
                        render={({ field }) => 
                            <FormControl sx={{minWidth: 120 }}>
                                <TextField disabled={isDisabled()}   error={!field.value} onChange={field.onChange} size="medium" variant="standard" value={field.value} label={MAPPING_METADATA_KEYS_TO_LABEL[key]}/>
                            </FormControl>
                        }
                    />
                ))
            }

        </Box>

    );
}

export default Component;