import { IProfile } from './../../../entities/profile/index';
import { logOut, me, signIn } from './thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAuthState {
	authenticated: boolean; 
  loading: boolean;
	profile: IProfile;
  error: any;
}

const initialState = { authenticated: false, loading: false } as IAuthState;

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
		authenticated: (state: IAuthState) => {
			state.authenticated = true;
		}
	},
  extraReducers: (builder) => {
    builder
      .addCase(me.pending, (state, action) => {state.loading = true})
      .addCase(me.fulfilled, (state, action) => {
        state.loading = false;
				state.profile = action.payload as IProfile;
				state.authenticated = true;
        console.log('state me thunk')
      })
      .addCase(me.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      .addCase(signIn.pending, (state, action) => {state.loading = true})
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
				state.profile = action.payload as IProfile;
				state.authenticated = true;
        console.log('state signin thunk')
      })
      .addCase(signIn.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })

      builder
      . addCase(logOut.fulfilled, (state, action) => {
        state.authenticated = false;
      })
  },
})

export default authSlice.reducer;
export const { authenticated } = authSlice.actions;
