export enum ROLES {
    ADMIN = 'admin',
    MEMBER = 'member',
    OPERATOR = 'operator'
}

const permissions = {
    certify: [ROLES.ADMIN],
    preCertify: [ROLES.ADMIN, ROLES.MEMBER],
    accessToRolesTab: [ROLES.ADMIN],
    addMemberToProject: [ROLES.ADMIN],
    createProject: [ROLES.ADMIN],
    deleteCertification: [ROLES.ADMIN]
}

export const filterByRole = (arr: any[], roles: string[]): any[] => {
    return arr.filter((ar) => ar['roles'].some((a: string) => roles.includes(a)));
}