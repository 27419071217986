import { Box } from "@mui/material";
import { IVerificationRequest, IVerificationResponse } from "../../../entities/organization";

interface IProps {
    vReq: IVerificationRequest;
    vRep: IVerificationResponse;
}

const Component: React.FC<IProps> = ({vRep, vReq}) => (
    <Box>
        <h1 style={{textAlign: "center"}}>
          <img 
            src="https://i.postimg.cc/pTYyFxj0/logo-minister.png" 
            width="100" 
            alt="Ecertdoc" 
          />
        </h1>
        <div>
            <h2 style={{textAlign: "center"}}>Bonjour {vReq.requesterName},</h2>
            <p style={{textAlign: "center"}}>
            {vRep.valid ? (
                <>
                    Nous confirmons la validité et l'authenticité du diplôme que vous avez demandé de vérifier. Vous trouverez l'attestation originale dans le lien suivant.
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <ul style={{textAlign: 'left'}}>
                            <li><strong>Nom:</strong> {vReq.firstname} {vReq.firstname} </li>
                            <li><strong>Université:</strong> {vReq.university} </li>
                            <li><strong>Diplôme:</strong> {vReq.speciality} </li>
                            {vReq?.matricule && (<li><strong>Matricule:</strong> {vReq.matricule}</li>)}
                            {vReq?.ine && (<li><strong>INE:</strong> {vReq.ine}</li>)}
                        </ul>
                    </div>
                </>
            ): (
                <span>
                    Nous regrettons de vous informer que nous n'avons pas pu valider l'authenticité de ce document pour la raison suivante: <span style={{color: 'red'}}>{vRep.reason}</span>
                </span>
            )}
            </p>
            {vRep.valid && (
                <div style={{textAlign: 'center', width: '100%', marginTop: '2rem'}}>
                    <a style={{textDecoration: 'none', color: 'white', background: '#81b8e8', padding: '1rem', borderRadius: '10px', fontWeight: 'bold'}} href={vRep.certificateLocation} target="_blank">
                        Voir l'attestation
                    </a>
                </div>
            )}
          <p style={{ marginTop: '3rem'}}>
            Merci, <br/>
            Mesrsi gov
          </p>
        </div>
    </Box>
);

export default Component;