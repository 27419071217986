import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getSignatairesLabels } from '../components/PrintDoc/helper';
import { CORE_API } from '../config/environment.dev';
import { client } from '../lib/http-client';
import { AppDispatch, IState } from '../lib/store';
import { loadCertificates } from '../lib/store/projects/thunks';

interface IProgram {
    name: string;
    firstSignataire: string;
    secondSignataire: string;
}

interface ICertConfig {
    institution: string;
    programs: IProgram[];
    conseilDate: string;
    finalite: string;
}

const getConfig = (univID: string) => client.get<ICertConfig>(`${CORE_API}/certificate/config/${univID}`,).then((res) => res.data);

const updateConfig = (filter: Record<string, string>, data: Record<string, string>) => client.put(`${CORE_API}/certificate/config`, {filter, data});

const getProgramByName = (n: string, programs: IProgram[]) => programs.find(({name}) => name === n)!;

const SignataireForm: React.FC<{programs: IProgram[], submit: (f: any) => void, loading: boolean, universityName: string}> = ({universityName, programs, submit, loading}) => {
    const [signataires, setSignataires] = React.useState<{left: String, right: string}>({left: '', right: ''});
    const [programSelected, setProgramSelected] = React.useState<string>();

    const onSelectChange = (v: any) => {
        const program = getProgramByName(v.target.value as string, programs);
        setProgramSelected(v.target.value);
        setSignataires({
            left: program.firstSignataire,
            right: program.secondSignataire
        });
    }

    const _submit = () => {
        submit({
            program_etude: programSelected,
            first_signataire: signataires?.left,
            second_signataire: signataires?.right
        });
    }

    return (
        <Box
            display="flex"
            justifyContent={'center'}
            flexDirection="column"
            gap="2rem"
            className="color-chart"
        >
            <FormControl sx={{ m: 1, minWidth: '40%' }} >
                <InputLabel id="demo-select-small-label">Programme</InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small-label"
                    label="Programme"
                    onChange={onSelectChange}
                >
                {
                   programs.map((p) => (
                        <MenuItem key={p.name}  value={p.name}>{p.name}</MenuItem>                
                    ))
                }
                </Select>
            </FormControl>

            {
                programSelected && (
                    <Box display="flex" justifyContent="space-between" gap={4}>
                        <FormControl fullWidth>
                            <TextField
                                label={getSignatairesLabels(universityName, programSelected).left}
                                id="left"
                                size="small"
                                variant="outlined"
                                InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                                value={signataires?.left}
                                onChange={(v) => setSignataires({...signataires, left: v.target.value})}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label={getSignatairesLabels(universityName, programSelected).right}
                                id="right"
                                size="small"
                                variant="outlined"
                                InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                                value={signataires?.right}
                                onChange={(v) => setSignataires({...signataires, right: v.target.value})}
                            />
                        </FormControl>
                    </Box>
                )
            }
            <LoadingButton loading={loading} fullWidth type="submit" variant="outlined" color="inherit" onClick={() => _submit()}>Enregistrer</LoadingButton>
        </Box>
    )
}


const DateConseilForm: React.FC<{label: string, submit: (date: string) => void, date: string, loading: boolean}> = ({label, submit, date, loading}) => {
    const [_date, setDate] = React.useState<string>(date);
    const _submit = () => submit(_date);
    return (
        <Box display="flex" flexDirection="column" gap={4}>
            <FormControl fullWidth>
                <TextField
                    label={label}
                    size="small"
                    variant="outlined"
                    InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                    value={_date}
                    onChange={(v) => setDate(v.target.value)}
                />
            </FormControl>
            <LoadingButton loading={loading} fullWidth type="submit" variant="outlined" color="inherit" onClick={() => _submit()}>Enregistrer</LoadingButton>
        </Box>
    )
}

export default function () {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const organisation = useSelector((state: IState) => state.organization.organization);
  const [config, setConfig] = React.useState<ICertConfig>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fetchLoading, setFetchLoading] = React.useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    getConfig(organisation.id!)
        .then(setConfig)
        .catch(() => {toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...')})
        .finally(() => setFetchLoading(false))

  }, [organisation]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const submitSignataires = (values: Record<string, string>) => {
    const {program_etude, ...data} = values;
    setLoading(true);
        updateConfig(
            {institution: config?.institution!, program_etude},
            data
        )
            .then(() => toast.success('Mise à jour'))
            .then(() => dispatch(loadCertificates(organisation.id!)))
            .catch(() => {toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...')})
            .finally(() => setLoading(false))
  }

  const submitText= (key: string) => (data: string) => {
    setLoading(true);
        updateConfig(
            {institution: config?.institution!},
            {[key]: data}
        )
            .then(() => toast.success('Mise à jour'))
            .then(() => dispatch(loadCertificates(organisation.id!)))
            .catch(() => {toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...')})
            .finally(() => setLoading(false))
  }


  return (
    <div>
        {
            fetchLoading ? (
                <Box display="flex" width="100%" justifyContent="center">
                    <CircularProgress />
                </Box>
            ):(
                <>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Signataires
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Configurez les signataires</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SignataireForm
                            programs={config?.programs || []}
                            submit={submitSignataires}
                            loading={loading} 
                            universityName={organisation.name}                
                        />
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Date du conseil</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                            Veuillez configurer la date du conseil
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DateConseilForm 
                                label={'Date du conseil'}
                                submit={submitText('conseil_date')}
                                loading={loading}
                                date={config?.conseilDate || ""}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>Finalité</Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                            Veuillez configurer la finalité
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DateConseilForm
                                label='Finalité'
                                submit={submitText('finalite')}
                                loading={loading}
                                date={config?.finalite || ""}
                            />
                        </AccordionDetails>
                    </Accordion> */}
                </>
            )
        }
    </div>
  );
}