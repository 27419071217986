import { ICertificateData, IRoleData, ITemplateData } from './../../entities/table/index';
import { IHeadCell } from "../../entities/table";

export const globalSearchTableHeader: readonly IHeadCell<ICertificateData>[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Statut',
  },
  {
    id: 'institution',
    numeric: false,
    disablePadding: false,
    label: 'Institution',
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Prénoms',
  },
  {
    id: 'birthDate',
    numeric: false,
    disablePadding: false,
    label: 'Date de naissance',
  },
  {
    id: 'ine',
    numeric: false,
    disablePadding: false,
    label: 'INE',
  },
  {
    id: 'matricule',
    numeric: false,
    disablePadding: false,
    label: 'Matricule',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: false,
    label: 'Diplôme',
  }
];

export const certficatesHeaders: readonly IHeadCell<ICertificateData>[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Statut',
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Prénoms',
  },
  {
    id: 'ine',
    numeric: false,
    disablePadding: false,
    label: 'INE',
  },
  {
    id: 'matricule',
    numeric: false,
    disablePadding: false,
    label: 'Matricule',
  },
  // {
  //   id: 'grade',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Diplôme',
  // },
  {
    id: 'printed',
    numeric: false,
    disablePadding: false,
    label: 'Imprimé',
  },
  // {
  //   id: 'speciality',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Spécialité',
  // },
  {
    id: 'annee_obtention',
    numeric: false,
    disablePadding: false,
    label: 'Année d\'obtention',
  },
];

export const batchVerificationHeaders: readonly IHeadCell<ICertificateData>[] = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Statut',
  },
  {
    id: 'reference',
    numeric: false,
    disablePadding: false,
    label: 'Référence',
  },
  {
    id: 'institution',
    numeric: false,
    disablePadding: false,
    label: 'Institution',
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
    label: 'Prénoms',
  },
  {
    id: 'birthDate',
    numeric: false,
    disablePadding: false,
    label: 'Date de naissance',
  },
  {
    id: 'matricule',
    numeric: false,
    disablePadding: false,
    label: 'Matricule',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: false,
    label: 'Diplôme',
  },
  // {
  //   id: 'speciality',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Spécialité',
  // },
  // {
  //   id: 'annee_obtention',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Année d\'obtention',
  // },
  {
    id: 'certificateLink',
    numeric: false,
    disablePadding: false,
    label: 'Certificat',
  },
];


export const certficatesByIneHeaders: readonly IHeadCell<ICertificateData>[] = [
  {
    id: 'ine',
    numeric: false,
    disablePadding: false,
    label: 'INE',
  },
  {
    id: 'matricule',
    numeric: false,
    disablePadding: false,
    label: 'Matricule',
  },
  {
    id: 'grade',
    numeric: false,
    disablePadding: false,
    label: 'Diplôme',
  },
  {
    id: 'speciality',
    numeric: false,
    disablePadding: false,
    label: 'Spécialité',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'annee_obtention',
    numeric: false,
    disablePadding: false,
    label: 'Année d\'obtention',
  }
];

export const rolesHeaders: readonly IHeadCell<IRoleData>[] = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'university',
    numeric: false,
    disablePadding: false,
    label: 'Université',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Rôle',
  },
  {
    id: 'joinAt',
    numeric: false,
    disablePadding: false,
    label: 'A rejoint le',
  },
];

export const templatesHeaders: readonly IHeadCell<ITemplateData>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nom',
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Auteur',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Créé le',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: false,
    label: 'Dernière modification',
  },
];

export interface IRoleItem {
  id: string;
  label: string;
}

export const roleItems: IRoleItem[] = [
  {
    id: 'admin',
    label: 'Responsable du site',
  },
  {
    id: 'operator',
    label: 'Operateur Mesrsi',
  },
  {
    id: 'member',
    label: 'Membre',
  }
];

export enum LOCALSTORAGE_KEYS {
  TOKEN = 'token',
  ORG_ID = 'org_id',
}

export const EN_ATTENTE = "en attente";

export const CERTIFICATION_MANDATORY_COLUMNS: string[] = [
  "institution",
  "nom",
  "prenoms",
  "ine",
  "grade",
  "specialite",
  "program_etude",
  "date_naiss",
  "lieu",
  "annee_obtention",
];

export const CERTIFICATION_MANDATORY_COLUMNS_HEADER: string[] = [
  'Institution',
  'Nom',
  'Prénoms',
  'Ine',
  'Grade',
  'Spécialité',
  'Programme d\'étude',
  'Date de naissance',
  'Lieu de naissance',
  'Année d\'obtention'
];

export const VERIFICATION_MANDATORY_COLUMNS: string[] = [
  "ref_fp",
  "institution",
  "nom",
  "prenoms",
  "date_naiss",
  "grade",
  "matricule",
  "annee_obtention",
];

export const VERIFICATION_MANDATORY_COLUMNS_HEADER: string[] = [
  'Reference',
  'Institution',
  'Nom',
  'Prénoms',
  'Date de naissance',
  'Diplôme',
  'Matricule',
  'Année d\'obtention'
];


export const MAPPING_METADATA_KEYS_TO_LABEL: Record<string, string> = {
  annee_obtention: 'Année d\'obtention',
  date_naiss: 'Date de naissance',
  grade: 'Diplôme',
  ine: 'INE',
  institution: 'Institution',
  lieu: 'Lieu de naissance',
  matricule: 'Matricule',
  mention: 'Mention',
  moy: 'Moyenne',
  nom: 'Nom',
  prenoms: 'Prénoms',
  program_etude: 'Programe d\'étude',
  pv_bac: 'PV Bac',
  specialite: 'Spécialité'
}