import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IInput } from "../../entities/certificates";
import { getMissingFields, normalizeCsvDataKeys, sanitizeData, validatedColumns } from "../../lib/parser";
import LoadCsv from "../LoadCsv";
import Table from "./Table";
interface IProps {
    mandatory: {
        columns: string[];
        header: string[];
    };
    setInputs: (inputs: IInput[]) => void;
}

const Component: React.FC<IProps> = ({mandatory, setInputs}) => {
    const [columns, setColumns] = useState<string[]>();
    const [rows, setRows] = useState<Record<string, string>[]>();
    const [corruptedRows, setCorruptedRows] = useState<Record<string, string>[]>();

    const setCsvData = (data: Record<string, string>[]) => {
        const rows: Record<string, string>[] = data.map(normalizeCsvDataKeys).map(sanitizeData);
        setColumns(Object.keys(rows[0]));
        setCorruptedRows(rows.filter((r) => r['wrong_date_naiss'] === 'true'));
        setRows(rows);
    }

    useEffect(() => {
        if (corruptedRows && corruptedRows.length > 0) {
            setInputs([]);
        } else {
            setInputs(rows!);
        }
    }, [rows]);

    return columns ?

        (
            validatedColumns(mandatory.columns, columns) ? 
                (
                    corruptedRows && corruptedRows.length > 0 ? (
                        <Box display="flex" flexDirection="column" gap={3}>
                            <Box component={Paper} p={2}>
                                <Typography color="error">Les dates de naissances dans le tableau suivant sont au mauvais format, veuillez corriger dans le csv.</Typography>
                            </Box>
                            <Table header={mandatory.header} columns={mandatory.columns!}  rows={corruptedRows!}/>
                            <Box><LoadCsv setCsvData={setCsvData} /></Box>          
                        </Box>
                    ) : <Table header={mandatory.header} columns={mandatory.columns!}  rows={rows!}/>
                ) : 
                (
                    <Box display="flex" flexDirection="column" gap={3}>
                        <Box component={Paper} p={2}>
                            <Typography>Veuillez ajouter les champs obligatoires suivants au fichier CSV:</Typography>
                        <div style={{color: 'red'}}>{getMissingFields(mandatory.columns, columns).map((f: string, idx: number) => <span key={idx} style={{display: 'block'}}>{f}</span>)}</div>
                        </Box>
                        <Box><LoadCsv setCsvData={setCsvData} /></Box>          
                    </Box>
                )
        )
        : (
            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <LoadCsv setCsvData={setCsvData} />
            </div>
        )
}

export default Component;