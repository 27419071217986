import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Typography } from './Typography';
import {ReactComponent as SchoolLogo} from '../../assets/images/school.svg';
import { useLocation } from 'react-router-dom';
import { Box, Button, IconButton, Menu, MenuItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { IOrganization } from '../../entities/organization';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../lib/store';
import { updateOrg } from '../../lib/store/organization/thunks';
import { toast } from 'react-toastify';
import EditOrgModal from '../EditOrgModal';
interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  collapsed: boolean;
  org: IOrganization;
  menuItemStyle: {
    icon: string;
    menuContent: string;
  };
}

const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div<{ rtl?: boolean }>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #009fdb;
  background: linear-gradient(45deg, rgb(21 87 205) 0%, rgb(90 225 255) 100%);
  ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `}
`;

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children, collapsed, org, menuItemStyle, ...rest }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  return (
    <>
      <StyledSidebarHeader {...rest}>
        <div style={{ display: 'flex'}}>
          {
            collapsed ? (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <SchoolLogo width="100%" fill={menuItemStyle.icon}/>
              </div>
              
            ): (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <SchoolLogo width="20%" height="50%" fill={menuItemStyle.icon} />
                <div style={{padding: '1rem', textAlign: 'left'}}>
                  <Tooltip title={org?.name}>
                      <div>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="body2" fontWeight={700} color={menuItemStyle.menuContent} >Universite</Typography>
                            <IconButton size='small'  sx={{color: menuItemStyle.menuContent}} onClick={() => setOpenEditModal(true)}>
                              <EditIcon sx={{fontSize: '15px'}} />
                            </IconButton>
                        </Box>

                        <Typography variant="body1" fontWeight={700} color={menuItemStyle.menuContent}>
                          {org?.name.substring(0, 20)}
                        </Typography>
                      </div>
                  </Tooltip>
                </div>
              </div>
            )
          }
        </div>
      </StyledSidebarHeader>
      
      <EditOrgModal org={org} open={openEditModal} close={() => setOpenEditModal(false)} />
    </>
  );
};
