import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';

interface IProps {
    actionLabel: string;
    alignLabel?: 'left' | 'right' | 'center';
    loading: boolean;
    open: boolean;
    contentText?: string | React.ReactElement;
    action: () => void;
    close: () => void;
    children?: React.ReactElement;
    succesLabel?: string;
    errorLabel?: string;
}

const CDialog: React.FC<IProps> = ({
    actionLabel, 
    open,
    loading,
    action,
    close,
    children,
    contentText='Confirmez vous cette action ?',
    succesLabel='Confirmer',
    errorLabel='Annuler',
    alignLabel='left'
}) => {
  return (
    <div style={{width: '100%'}}>
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" textAlign={alignLabel}>
                {actionLabel}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {children || contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} color="error" >{errorLabel}</Button>
                <LoadingButton loading={loading}  onClick={action} color="success" autoFocus>{succesLabel}</LoadingButton>
            </DialogActions>
        </Dialog>
    </div>
  );
}

export default CDialog;