import { createSlice } from '@reduxjs/toolkit';
import { IProject } from '../../../entities';
import { ICertificateDTO } from '../../../entities/projects';
import { ISession } from '../../../entities/verification';
import { getVerificationSessions, initBatchVerificationSession } from './thunks';

export interface IVerificateState {
    sessions: ISession[];
    loading: boolean;
}

const initialState = { sessions: [], loading: false } as IVerificateState;

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVerificationSessions.pending, (state, action) => {state.loading = true})
      .addCase(getVerificationSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.sessions = action.payload;
      })
      .addCase(getVerificationSessions.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });
  },
});

export default verificationSlice.reducer;
