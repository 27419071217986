import { createAsyncThunk } from "@reduxjs/toolkit";
import { IState } from "..";
import { ISession } from "../../../entities/verification";
import { client } from "../../http-client";
import { USER_API } from "../../../config/environment.dev";
import { formatDate, getSessionByID } from "../../helper";

const countCertified = (req: Record<string, string>[]) => req.reduce((acc, curr) => {
    if (curr['certified'] === "true") {
        acc = acc + 1;
        return acc;
    }
    return acc;
}, 0)

export const getSession = createAsyncThunk(
    'verification/getOne',
    async (id: string, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            const s = getSessionByID(state.verification.sessions, id);
            // const response = await client.get<ISession>(`${USER_API}/verification-session/${id}`,);
            return {
                ...s,
                total: s.requests?.length || 0,
                nbCertified: countCertified(s.requests || []),
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const initBatchVerificationSession = createAsyncThunk(
    'verification/session/init',
    async (requests: Record<string, string>[], thunkAPI) => {
        try {
            await client.post(`${USER_API}/verification-request/session`, {requests});             
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getVerificationSessions = createAsyncThunk(
    'verification/session/getAll',
    async () => {
        try {
            const response = await client.get<ISession[]>(`${USER_API}/verification-request/session`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const runSession = createAsyncThunk(
    'verification/session/run',
    async (id: string, _) => {
        try {
            await client.post(`${USER_API}/verification-request/session/run/${id}`);
        } catch(error) {
            return Promise.reject(error);
        }
    }
);