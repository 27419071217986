import { TableRow, TableCell, Checkbox, Chip, alpha } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IBodyProps, ICertificateData } from "../../../entities/table";
import { findIndexFunc, getComparator } from "../../../lib/helper";

interface IProps extends IBodyProps<ICertificateData> {}

export const Body: React.FC<IProps> = ({rows, order, orderBy, page, rowsPerPage, currentSelectedStatus, itemsSelected, handleClick, isSelected, onRowClick, variant='A'}) => {
    const navigate = useNavigate();
    return (
        <>
            {rows.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.hash!);
                  const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <TableRow
                      hover
                      onClick={(event) => onRowClick ? onRowClick(row) : navigate(`/projects/${row.projectID}/${row.hash}`)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.hash}
                      selected={isItemSelected}
                      sx={{bgcolor: (theme) => isItemSelected ? `${alpha('#07bc0c', theme.palette.action.activatedOpacity)} !important` : ''}}
                    >
                      {variant === 'A' && (
                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row, findIndexFunc)}>
                          <Checkbox
                            sx={{
                              display: !currentSelectedStatus ? '' : 
                                (
                                  row.status === currentSelectedStatus ? '' : 'none'
                                )
                            }}
                            color={"success"}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      )}

                      {variant === 'B' && (
                        <TableCell padding="checkbox" onClick={(event) => handleClick(event, row, findIndexFunc)}>
                          <Checkbox
                            sx={{display: itemsSelected?.length! === 0 ? '' : isItemSelected ? '' : 'none' }}
                            color={"success"}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell >{row.ine}</TableCell>
                      <TableCell >{row.matricule}</TableCell>
                      <TableCell >{row.grade}</TableCell>
                      <TableCell >{row.speciality}</TableCell>
                      <TableCell> <Chip sx={{borderRadius: 0, background: row.color, color: '#FFFF'}} label={row.status}/> </TableCell>
                      <TableCell>{row.date}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}