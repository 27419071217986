import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { AppDispatch } from '../../lib/store';
import { me } from '../../lib/store/auth/thunks';

export const LoginCallback: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const params = new URLSearchParams(window.location.search);

    if (Boolean(params.get('success'))) {
        dispatch(me());
        return <Navigate to="/projects" replace />
    }

    toast.error(`failed to authenticate`);
    return <Navigate to="/login"/>
}