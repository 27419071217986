import { addMembersToProject, createBatchPreCertificates, createPreCertificate, createProject, getCertificationsByProjectID, getProjectByID, getProjects, loadCertificates, updateCertificate, setCertificateAsPrinted } from './thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IProject } from '../../../entities';
import { ICertificateDTO } from '../../../entities/projects';

export interface IProjectState {
  projects: Record<string, IProject>;
  certificates: ICertificateDTO;
  loading: boolean;
}

const initialState = { projects: {}, loading: false } as IProjectState;

const projectsSlice = createSlice({
  name: 'certifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state, action) => {state.loading = true})
      .addCase(getProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = (action.payload as IProject[]).reduce((acc, curr) => ({...acc, [curr.id!]: curr}), {}) as Record<string, IProject>;
      })
      .addCase(getProjects.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

    builder
      .addCase(loadCertificates.pending, (state, action) => {state.loading = true})
      .addCase(loadCertificates.fulfilled, (state, action) => {
        state.loading = false;
        state.certificates = action.payload;
      })
      .addCase(loadCertificates.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

    builder
      .addCase(getProjectByID.pending, (state, action) => {state.loading = true})
      .addCase(getProjectByID.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = {
          ...state.projects,
          [action.payload.id!]: action.payload
        }
      })
      .addCase(getProjectByID.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

    // Create project
    builder
      .addCase(createProject.pending, (state, action) => {state.loading = true})
      .addCase(createProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = {
          ...state.projects,
          [action.payload.id!]: action.payload
        }
      })
      .addCase(createProject.rejected, (state, action) => {console.log(action)});

    // get certificates
    builder
      .addCase(getCertificationsByProjectID.pending, (state, action) => {state.loading = true})
      .addCase(getCertificationsByProjectID.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = {
          ...state.projects,
          [action.payload.projectID!]: {
            ...state.projects[action.payload.projectID!],
            certificates: action.payload.certificates
          }
        }
      })
      .addCase(getCertificationsByProjectID.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

    // create precertificate
    builder
      .addCase(createPreCertificate.pending, (state, action) => {state.loading = true})
      .addCase(createPreCertificate.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = {
          ...state.projects,
          [action.payload.projectID!]: {
            ...state.projects[action.payload.projectID!],
            certificates: [...(state.projects[action.payload.projectID!].certificates || []), action.payload],
            numberOfPendingCertificates: state.projects[action.payload.projectID!].numberOfPendingCertificates! + 1
          }
        }
      })
      .addCase(createPreCertificate.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

    builder
      .addCase(addMembersToProject.pending, (state, action) => {state.loading = true})
      .addCase(addMembersToProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projects = {
          ...state.projects,
          [action.payload.projectID!]: {
            ...state.projects[action.payload.projectID!],
            members: [...state.projects[action.payload.projectID!].members!, ...action.payload.members!]
          }
        }
      })
      .addCase(addMembersToProject.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
      });

      builder
        .addCase(setCertificateAsPrinted.fulfilled, (state, action) => {
          state.projects = {
            ...state.projects,
            [action.payload.projectID!]: {
              ...state.projects[action.payload.projectID!],
              certificates: [
                ...state.projects[action.payload.projectID!].certificates!.filter(({hash}) => hash !== action.payload.hash),
                action.payload
              ]
            }
          }
        })
        .addCase(setCertificateAsPrinted.rejected, (state, action) => {
          console.log(action);
          state.loading = false;
        });
  },
});

export default projectsSlice.reducer;
