import Button from "@mui/material/Button"
import { ChangeEvent } from "react"
import Papa from "papaparse";

interface IProps {
    setCsvData: (data: Record<string, string>[]) => void
}

const Component: React.FC<IProps> = ({setCsvData}) => {
    const onCsvLoad = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            Papa.parse<Record<string, string>>(event.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: (results) => setCsvData(results.data)  
            })
        }
    }

    return (
        <Button variant="outlined" color="inherit" sx={{color: 'rgb(68, 89, 110)'}}>
            <label htmlFor="logo">
                Charger le fichier csv
                <input id="logo" type="file" name="myImage" accept=".csv" style={{display: 'none', width: '100%'}} onChange={onCsvLoad} />
            </label>
        </Button>
    )
}

export default Component;