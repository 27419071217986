import { LoadingButton } from "@mui/lab";
import { Box, Typography, TextField, useMediaQuery } from "@mui/material";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SplittedView } from "../components/SplittedLayout";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../lib/store";
import { signUp } from "../lib/store/auth/thunks";
import SingnupReconfirmation from "../components/SingnupReconfirmation.tsx";
import certificateLog from "../assets/images/diploma_img.png";
import armoirie from "../assets/images/armoirie.png";

const mdpValidationRule = {
    required: {
        value: true,
        message: 'mot de passe obligatoire'
    },
    minLength: {
        value: 8,
        message: 'le mot de passe doit avoir 8 caractères au moins',
    },
}

const Left: React.FC = () => <div style={{display: 'flex', justifyContent: 'center'}}><img src={certificateLog} width="60%" height="100%"/></div>;

const Right: React.FC<{loading: boolean, submit: (data: any) => void}> = ({submit, loading}) => {
    const { register, handleSubmit, formState: {errors}, getValues } = useForm();
    const [isSafari, setIsSafari] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:800px)');
    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafari)
    }, []);
    return (
        <Box display="flex" flexDirection="column" height="100%" width={isSafari ? undefined : matches ? undefined : '100%'} p={5} className="color-chart">
            <Box display="flex" justifyContent="space-between" className="register">
                <Typography fontWeight="bold" variant="h5" textAlign="left">Création compte</Typography>
                <img src={armoirie} width="20%"/>
            </Box>
            <form onSubmit={handleSubmit(submit)}
                style={{display: 'flex', flexDirection: 'column'}}> 
                <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="prénom" {...register('firstname', {required: true})} />
                {errors.firstname && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>Veuillez entrer un prenom valide.</Typography>}
                <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="nom"  {...register('lastname', {required: true})}/>
                {errors.lastname && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>Veuillez entrer un nom valide</Typography>}
                <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="email"  {...register('email', {
                    required: true,
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "adresse email invalide"
                    }
                })}/>
                {errors.email && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>Veuillez entrer une adresse email valide</Typography>}
                <TextField type="password" variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="mot de passe"  {...register('password', {...mdpValidationRule})}/>
                {errors.password && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>{errors.password.message?.toString()}</Typography>}
                <TextField type="password" variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="Confirmer mot de passe"
                    {
                        ...register(
                            'confirmPassword',
                            {
                                ...mdpValidationRule,
                                validate: (data: string) => (getValues()['password'] !== data && 'ce champ doit etre identique au champ mot de passe') || undefined,
                            }
                        )
                }/>
                {errors.confirmPassword && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left'}}>{errors.confirmPassword.message?.toString()}</Typography>}
                <div style={{marginTop: '2rem', width: '100%', alignItems: 'center'}}>
                    <LoadingButton style={{width: '50%'}} color="inherit" variant="outlined" type="submit" loading={loading}>Créer</LoadingButton>
                </div>
            </form>
        </Box>
    );
}

const Welcome: React.FC<{email: string}> = ({email}) => {
    const { register, handleSubmit, formState: {errors}, getValues } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);

    const submit = (data: any) => {
        Promise.resolve()
            .then(() => setSent(true))
    }
    return (
        <Box display="flex" flexDirection="column" alignItems="center" p="1rem" className="color-chart">
            <div>
                <Typography fontWeight="bold" variant="h5" mb="1rem">🎉 Félicitations 🎉</Typography>
                <Typography textAlign="left" mb="1rem">Votre compte a bien été créé, un mail de verification vous a été envoyé sur votre adresse mail: <strong style={{color: 'green'}}>{email}</strong>.
                    Merci de le valider pour finaliser votre inscription.
                </Typography>
                <Typography textAlign="left">Renvoyez le mail à cette adresse:</Typography>
            </div>

            <SingnupReconfirmation />
        </Box>
    )
}

export const SignUp: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>();
    const params = new URLSearchParams(window.location.search);
    const matches = useMediaQuery('(min-width:600px)');

    const submit = (data: any) => {
        console.log(data);
        setLoading(true);
        dispatch(signUp({...data, ivtID: params.get('ivtID')})).unwrap()
            .then(() => setEmail(data['email']))
            .catch(() => toast.error('une erreur est survenue, veuillez re-essayer. Si le problème persiste contactez nous !'))
            .finally(() => setLoading(false))
    };

    return (
        <SplittedView
            Left={Left}
            styleLeft={{padding: matches ? '3rem' : '1rem'}} 
            Right={email ? () => <Welcome email={email}/> : () => <Right submit={submit} loading={loading}/>}
            paperStyle={{backgroundColor: 'white'}}
        />
    );
}