import { ListItemIcon as MuiListItemIcon } from "@mui/material"
import styled from "styled-components";

export const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 40px !important;
`;

export const Image = styled.img`
  width: 2.7rem;
  border-radius: 50%;
`;
