import * as React from 'react';

import { Head } from './Head';
import { Body } from './Body';
import Table from '../Table';
import { ITemplateData } from '../../entities/table';
import Toolbar from './Toolbar';
import { useNavigate } from 'react-router-dom';

interface IProps {
  rows: ITemplateData[],
}

export const TemplatesTable: React.FC<IProps> = ({rows}) => {
  const navigate = useNavigate();
  return <Table Head={Head} Body={Body} rows={rows} Toolbar={() => <Toolbar goToNewTemplate={() => navigate('new')} />}/>;
}
