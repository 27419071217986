import { IState } from './../index';
import { IAdditionalData, IBatchCertRequest, ICertPrinted, ICertificate } from './../../../entities/certificates/index';
import { IAddMembersToProjectRequest, ICertificateDTO, IProject } from './../../../entities/projects/index';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CORE_API, USER_API } from '../../../config/environment.dev';
import { client } from '../../http-client';


export const createProject = createAsyncThunk(
    'projects/createNewProject',
    async (project: IProject, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            const response = await client.post<IProject>(`${USER_API}/project`, 
                {
                    ...project,
                    organizationID: state.organization.organization.id
                }, 
                {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getCertificationsByProjectID = createAsyncThunk(
    'projects/getCertificationsByProjectID',
    async (projectID: string, thunkAPI) => {
        try {
            const response = await client.get<ICertificate[]>(`${USER_API}/project/certificate?projectID=${projectID}`, {withCredentials: true});
            return {projectID: projectID, certificates: response.data}
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getCertificationsByIne = createAsyncThunk(
    'projects/getCertificationsByIne',
    async (ine: string, _) => {
        try {
            const response = await client.get<ICertificate[]>(`${USER_API}/project/certificate/${ine}`, {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getCertificatesByFilters = createAsyncThunk(
    'projects/getCertificationsByIne',
    async (req: Record<string, string>, _) => {
        try {
            const queries: string = Object.entries(req).reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
            const response = await client.get<ICertificate[]>(`${USER_API}/project/certificate/filters?${queries}`, {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const setCertificateAsPrinted = createAsyncThunk(
    'projects/setCertificateAsPrinted',
    async (hash: string, _) => {
        try {
            const response = await client.put<ICertificate>(`${USER_API}/project/certificate/printed/${hash}`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const updateCertificate = createAsyncThunk(
    'projects/updateCertificate',
    async (certificate: ICertificate, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            await client.put<ICertificate>(`${CORE_API}/certificate`, {certificate});
            await thunkAPI.dispatch(loadCertificates(state.organization.organization.id!));
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const certPrinted = createAsyncThunk(
    'projects/certPrinted',
    async (data: ICertPrinted[], thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            await client.post<ICertificate>(`${CORE_API}/certificate/printed`, {data});
            await thunkAPI.dispatch(loadCertificates(state.organization.organization.id!));
        } catch(error) {
            return Promise.reject(error);
        }
    }
);
interface ICreatePreCertificate {
    projectID: string;
    file: File;
    additionalData: IAdditionalData[];
}

export const createPreCertificate = createAsyncThunk(
    'projects/createPreCertificate',
    async (preCertificate: ICreatePreCertificate, thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            const formData = new FormData();
            formData.append('university', state.organization.organization.name);
            formData.append('projectID', preCertificate.projectID);
            formData.append('file', preCertificate.file);
    
            const response = await client.post<ICertificate>(`${CORE_API}/pre-certify`, formData, {
                withCredentials: true,
            });
            const user = state.auth.profile;
            return {
                ...response.data,
                createdBy: user.lastname + ' ' + user.firstname,
            }
        } catch(error) {
            return Promise.reject(error);
        }
    }
)

export const createBatchPreCertificates = createAsyncThunk(
    'projects/createBatchPreCertificates',
    async (data: Record<string, string>[], thunkAPI) => {
        try {
            const state = thunkAPI.getState() as IState;
            const user = state.auth.profile;
            const req: IBatchCertRequest = {
                university: state.organization.organization.name,
                institutionLogo: state.organization.organization.logo!,
                orgID: state.organization.organization.id!,
                author: user.firstname + ' ' + user.lastname,
                data
            }

            console.log({req});
            await client.post<ICertificate[]>(`${CORE_API}/batch-precertify`, req);
            await thunkAPI.dispatch(loadCertificates(req.orgID!));
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getProjects = createAsyncThunk(
    'projects/getProject',
    async (organizationID: string, thunkAPI) => {
        try {
            const response = await client.get<IProject[]>(`${USER_API}/project?organizationID=${organizationID}`, {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const loadCertificates = createAsyncThunk(
    'projects/loadCertificates',
    async (organizationID: string, _) => {
        try {
            const response = await client.get<ICertificateDTO>(`${USER_API}/project/certificates?orgID=${organizationID}`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getProjectByID = createAsyncThunk(
    'projects/getProjectByID',
    async (id: string, thunkAPI) => {
        try {
            const response = await client.get<IProject>(`${USER_API}/project/${id}`, {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const certify = createAsyncThunk(
    'projects/certify',
    async (hashes: string[], thunkAPI) => {
        try {
            const response = await client.post<string[]>(`${CORE_API}/certify`, {hashes}, {withCredentials: true});
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const addMembersToProject = createAsyncThunk(
    'projects/addMemberToProject',
    async (req: IAddMembersToProjectRequest, thunkAPI) => {
        try {
            await client.post<IProject>(`${USER_API}/project/add-members/${req.projectID}`, req);
            const state = thunkAPI.getState() as IState;
            const orgMembers = state.organization.organization.members;
            return {
                projectID: req.projectID,
                members: orgMembers?.filter(({id}) => req.memberIDs.includes(id!))
            };
        } catch(error) {
            return Promise.reject(error);
        }
    }
);