import { LoadingButton } from "@mui/lab";
import { Modal, Container, Typography, Box, Stack, Button, TextField } from "@mui/material";
import { IOrganization } from "../../entities/organization";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AppDispatch } from "../../lib/store";
import { updateOrg } from "../../lib/store/organization/thunks";

interface IProps {
    org: IOrganization;
    open: boolean;
    close: () => void;
}

const Component: React.FC<IProps> = ({org, open, close}) => {
    const dispatch = useDispatch<AppDispatch>();

    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>(org.name);
    const [logo, setLogo] = useState<File>();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files || [];
        if (files.length > 0) {
            setLogo(files[0]);
        }
    };

    const onNameChange = (event: any) => setName(event.target.value);

    const update = (event: any) => {
        event.preventDefault();
        setLoading(true);
        dispatch(updateOrg({id: org.id!, name: name!, logo: logo, address: org.address!}))
            .unwrap()
            .then(() => {toast.success('Nom mis à jour avec succès'); close();})
            .catch(() => toast.error('Une erreur est survenue, veuillez contacter le support si l\'erreur persiste...'))
            .finally(() => setLoading(false));
    }

    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        
            >
            <Box component={"form"} onSubmit={update} className="color-chart"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={3}
                sx={{background: 'white', p: 2, pt: 0, height: '60%', width: '40%'}}
            >
                <div style={{textAlign: 'center', padding: '1rem'}}>
                    <Typography sx={{fontWeight: 'bold'}}>Modifier université</Typography>
                </div>
                <TextField fullWidth variant="standard" defaultValue={name} style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="Nom" required onChange={onNameChange} />
                <Button fullWidth variant="outlined" color={logo ? 'success' : 'secondary'} style={{marginTop: '1rem'}}>
                    <label htmlFor="logo">
                        {logo ? 'changer le logo' : 'ajouter un logo'}
                        <input id="logo" type="file" name="myImage" accept="image/png, image/gif, image/jpeg" style={{display: 'none', width: '100%'}} onChange={onChange}/ >
                    </label>
                </Button>
                <Stack direction="row" justifyContent="space-between" p="1rem 7rem">
                    <Button variant="outlined"sx={{right: '1rem'}} onClick={close} color="error">Annuler</Button>
                    <LoadingButton variant="outlined" type="submit" loading={loading} color="inherit">Valider</LoadingButton>
                </Stack>
            </Box>
        </Modal>
    );
}

export default Component;