import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, Divider, FormControl, IconButton, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { GlobalSearchTable } from "../components/GlobalSearchTable";
import { ICertificate } from "../entities/certificates";
import { certificatesTableDataMapper } from "../lib/helper";
import { MAPPING_METADATA_KEYS_TO_LABEL } from "../lib/referentiel";
import { AppDispatch } from "../lib/store";
import { getCertificatesByFilters } from "../lib/store/projects/thunks";

interface IProps {
    open: boolean;
    close: () => void;
    submit: (filters: Record<string, string>) => void;
    loading: boolean;
}

const filters = [
    'institution',
    'ine',
    'matricule',
    'nom',
    'prenoms',
    'date_naiss',
    'grade',
    'annee_obtention'
]

const Drawer: React.FC<IProps> = ({open, close, submit, loading}) => {
    const { control, handleSubmit } = useForm();
    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={close}
            onOpen={console.log}
            
        >
            <Box display="flex" flexDirection="column">
                <IconButton onClick={close} ><CloseIcon /></IconButton>
                <Divider />
                <Box padding={5} pt={1} component="form" onSubmit={handleSubmit(submit)} display="flex" flexDirection="column" gap={2} className="color-chart">
                    {filters.map((f) => (
                        <Controller
                            key={f}
                            name={f}
                            control={control}
                            defaultValue=""
                            render={({ field }) => 
                                <FormControl sx={{minWidth: 120 }}>
                                    <TextField 
                                        onChange={field.onChange}
                                        size="medium"
                                        variant="standard"
                                        value={field.value} label={MAPPING_METADATA_KEYS_TO_LABEL[f]}
                                    />
                                </FormControl>
                            }
                        />
                    ))}
                    <LoadingButton loading={loading} fullWidth type="submit" variant="outlined" color="inherit">Rechercher</LoadingButton>
                </Box>
            </Box>
            
        </SwipeableDrawer>
    )
}

export default () => {
    const dispatch = useDispatch<AppDispatch>();
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [certificates, setCertificates] = useState<ICertificate[]>();
    const [loading, setLoading] = useState<boolean>(false);

    const search = (filters: Record<string, string>) => {
        setLoading(true);
        dispatch(getCertificatesByFilters(filters)).unwrap()
            .then((certificates) => {setCertificates(certificates); console.log(certificates)})
            .catch(() => toast.error('une erreur est survenue lors du chargement des certificats. Veuillez contacter le support pour plus d\infos.'))
            .finally(() => setLoading(false));
    }
    return (
        <>
            <Box display="flex" flexDirection="column" gap={3} className="color-chart">
                <Box><Button variant="outlined" color="inherit" onClick={() => setOpenFilter(true)}> <TuneIcon /> Filtres</Button></Box>
                {certificates && (certificates.length > 0 ? (
                    <GlobalSearchTable rows={certificatesTableDataMapper(certificates)} />
                ): (
                    <Typography>Pas de résultat pour ce filtre</Typography>
                ))}
            </Box>
        
            <Drawer open={openFilter} close={() => setOpenFilter(false)} submit={search} loading={loading} />
        </>
    )
}