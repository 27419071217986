import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CertificatesTable } from "../components/CertificatesTable";
import { MemberPannel } from "../components/MemberPannel";
import { shallowEqual, useSelector } from "react-redux";
import { IState } from "../lib/store";
import { certificatesTableDataMapper } from "../lib/helper";
import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { ICertificate } from "../entities/certificates";


export const Certificates: React.FC = () => {
    const { year, grade, program } = useParams();
    const navigate = useNavigate();
    const certificates = useSelector((state: IState) => state.projects.certificates, shallowEqual);
    let currentCerts: ICertificate[] = [];
    if (certificates && year && grade && program) {
        currentCerts = certificates[year!][grade!][program!] as ICertificate[];
    }
    const rows = certificatesTableDataMapper(currentCerts || []);

    useEffect(() => {
        if (!certificates) {
            navigate('/');
        }
    }, []);
    
    return (
        <Box display="flex" flexDirection="column" gap={4} height="100%">
            <Box sx={{display: 'flex', gap: '2rem', width: '100%', height:'100%', overflow: 'scroll'}}>
                <Box flexGrow={1} ><CertificatesTable rows={rows} /></Box>
                {/* <MemberPannel members={project?.members!} projectID={project?.id!}/> */}
            </Box>
        </Box>
    )
}