import { Container, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

interface IProps {
    onSearch: (term: string) => void
    label?: string;
}

const debounteTime = 500;

const Component: React.FC<IProps> = ({onSearch, label="INE..."}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debounceTerm, setDebounceTerm] = useState<string>("");

  useEffect(() => {
    const timeout = setTimeout(() => setSearchTerm(debounceTerm), debounteTime);
    return () => clearTimeout(timeout);
  }, [debounceTerm])

  useEffect(() => {
    onSearch(searchTerm);
  }, [searchTerm])
  

  return (
    <Container maxWidth="md">
      <TextField
        id="search"
        type="search"
        label={label}
        variant="standard"
        color="info"
        value={debounceTerm}
        sx={{width: '80%'}}
        onChange={(event) => setDebounceTerm(event?.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  );
}

export default Component;