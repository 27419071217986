import { Typography, Button, useMediaQuery } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import AddIcon from '@mui/icons-material/Add';

interface IProps {
  goToNewTemplate: () => void;  
}

export default (props: IProps) => {
  const matches = useMediaQuery('(min-width:800px)');

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          display: 'flex',
          justifyContent: 'space-between'
        }}
        className="color-chart"
      >
        <Typography
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Templates
        </Typography>
        <Button onClick={props.goToNewTemplate} variant="outlined" color="inherit" sx={{color: 'rgb(68, 89, 110)'}}> <AddIcon /> {matches ? ' Nouveau template' : ''} </Button>
      </Toolbar>
    );
}