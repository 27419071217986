import { QRCodeCanvas } from "qrcode.react";
import logoMesrsi from "../../assets/images/logo-MESRSI.png";
import rectSignature from "../../assets/images/recteur-signature.png";
import { ICertificate } from "../../entities/certificates";
import { useSelector } from "react-redux";
import { IState } from "../../lib/store";
import { getCertificateQrcodeValue, resolveGrade } from "../../lib/helper";

interface IProps {
    certificate: ICertificate;
}

const Component: React.FC<IProps>= ({certificate}) => {
    const organization = useSelector((state: IState) => state?.organization?.organization);

    const getLogo = () => {
        if (organization.logo && organization.logo.trim().length > 0) {
            return organization.logo;
        }
        return logoMesrsi;
    }

    return (
        <div className="certificate">
            <div className="header">
                <div className="header1">
                    <div className="logo">
                        <img width="40%" src={getLogo()} alt="" />
                    </div>
                    <div >
                        <QRCodeCanvas size={60} fgColor="#005e7a" value={getCertificateQrcodeValue(certificate.hash!)} />
                    </div>
                </div>

                <h4 className="diplom">
                    Attestation de reconnaissance de diplôme {resolveGrade(certificate?.grade)}
                </h4>
            </div>

            <div  className="laureat"> 
                <span>{certificate.metadata!['prenoms']} {certificate.metadata!['nom']}</span>
            </div>

            <div className="birth">
                Date de naissance : <span>{certificate.metadata!['date_naiss']}</span> | Lieu de naissance : <span>{certificate.metadata!['lieu']}</span>
            </div>

            <div className="period">
                <strong><span style={{display: 'block'}}>A rempli de façon satisfaisante toutes les exigences du diplôme</span></strong>
                <span>A obtenu le diplôme académique suivant :</span>
            </div>
            
            <div className="degree-name">
                {certificate.metadata!['specialite']}
            </div>

            <div className="annee">Année d'obtention: {certificate.metadata!['annee_obtention']}</div>

            <div className="signature">
                <div className="logo">
                    <img width="40%" src={logoMesrsi} alt="" />
                </div>
                <div className="sign">
                    <span className="author">Dr. Oumar Doumbouya</span>
                    <span>Directeur Général de l'Enseignement supérieur</span>
                    <div style={{textAlign: 'right'}}>
                        <img src={rectSignature} alt="" width="200px" />
                    </div>
                </div>
            </div>
    </div>
    )
}

export default Component;