import { TableRow, TableCell, Checkbox, Chip, alpha } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IBodyProps, ICertificateData } from "../../../entities/table";
import { STATUS, getComparator } from "../../../lib/helper";
import { ICertificate } from "../../../entities/certificates";

interface IProps extends IBodyProps<ICertificateData> {}

const displayCheckBox = (status: string, currentSelectedStatus: string): string => {
  if (!status || status === '') {
    return 'none'
  }

  if (!!currentSelectedStatus) {
    return status === currentSelectedStatus ? '' : 'none';
  }
  
  return '';
}

const findIndexFunc = (selected: readonly ICertificateData[], row: ICertificateData) => selected.findIndex(r => row.id === r.id);

export const Body: React.FC<IProps> = ({rows, order, orderBy, page, rowsPerPage, currentSelectedStatus, itemsSelected, handleClick, onRowClick, variant='A'}) => {
    const navigate = useNavigate();
    const isSelected = (id: string) => (itemsSelected || []).findIndex((r) => r.id === id) !== -1;
    return (
      <>
        {rows.slice().sort(getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            const isItemSelected = isSelected(row.id!);
            const labelId = `enhanced-table-checkbox-${index}`;
          return (
              <TableRow
                hover
                // onClick={(event) => onRowClick ? onRowClick(row) : navigate(row.hash)}
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id!}
                selected={isItemSelected}
                sx={{bgcolor: (theme) => {
                  if (row.status === '') {
                    return '#f6bab3';
                  }
                  return isItemSelected ? 'rgba(195, 225, 240, 1) !important' : '';
                }}}
              >
                {variant === 'A' && (
                <TableCell padding="checkbox" onClick={(event) => handleClick(event, row, findIndexFunc)}>
                  <Checkbox
                      sx={{
                        display: displayCheckBox(row.status, currentSelectedStatus)
                      }}
                      color={"success"}
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                )}
                <TableCell> {row.status && row.status !== '' && (<Chip sx={{borderRadius: 0, background: row.color, color: '#FFFF'}} label={row.status}/>)} </TableCell>
                <TableCell>{row.reference}</TableCell>
                <TableCell >{row.institution}</TableCell>
                <TableCell >{row.lastname}</TableCell>
                <TableCell >{row.firstname}</TableCell>
                <TableCell >{row.birthDate}</TableCell>
                <TableCell >{row.matricule}</TableCell>
                <TableCell >{row.grade}</TableCell>
                {/* <TableCell>{row.annee_obtention}</TableCell> */}
                <TableCell> {row.hash !== '' && (<a href={row.certificateLink}>{row.certificateLink?.slice(0, 17)}</a>)}</TableCell>
              </TableRow>
            );
        })}
      </>
    );
}