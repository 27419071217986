import styled from "styled-components";
import { AppBar as MuiAppBar } from "@mui/material";
import { Toolbar as MuiToolbar } from "@mui/material";

const drawerWidth = '240';

export const AppBar = styled(MuiAppBar)`
    min-height: 64px;
    background-color: white !important;
`;

export const Toolbar = styled(MuiToolbar)`
    display: flex;
    justify-content: flex-end;
    background-color: white;
`;