import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';

interface IFile {
    location: string;
    filename: string;
}

export const downloadAsZip = (files: IFile[], zipName: string) => {
	const zip = new JSZip();
	const promises = files.map(file => {
		return axios.get(file.location, { responseType: 'blob' }).then(response => {
			zip.file(file.filename, response.data);
		});
	});

	return Promise.all(promises).then(() => {
		zip.generateAsync({ type: 'blob' }).then(content => {
			saveAs(content, `${zipName}.zip`);
		});
	});
}