import { Box, IconButton } from "@mui/material";
import React from "react";
import { MenuItemStyles, menuClasses, Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, IState } from "../../lib/store";
import GeneralSidebarMenu from "../GeneralSidebarMenu";
import { SidebarHeader } from "./SidebarHeader";
import { hexToRgba, themes } from "./lib";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import guineeFlag from "../../assets/images/drapeau-guine-4.png";
import { Typography } from "./Typography";
import { Switch } from "./Switch";
import { resetOrganization } from "../../lib/store/organization/thunks";


type Theme = 'light' | 'dark';

interface IProps {
    setBroken: (v: boolean) => void;
    toggled: boolean;
    setToggled: (v: boolean) => void;
    collapsed: boolean;
    setCollapsed: (v: boolean) => void;

}

const Component: React.FC<IProps> = ({setBroken, toggled, setToggled, collapsed, setCollapsed}) => {
    const [theme, setTheme] = React.useState<Theme>('dark');
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const organization = useSelector((state: IState) => state?.organization?.organization, shallowEqual);
  
    const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTheme(e.target.checked ? 'dark' : 'light');
    };
  
    const menuItemStyles: MenuItemStyles = {
      root: {
        fontSize: '13px',
        fontWeight: 400,
        '&.active': {
          backgroundColor: hexToRgba(themes[theme].menu.active.backgroundColor, 1),
          color: themes[theme].menu.active.color,
        },
      },
      icon: {
        color: themes[theme].menu.icon,
        [`&.${menuClasses.disabled}`]: {
          color: themes[theme].menu.disabled.color,
        },
      },
      SubMenuExpandIcon: {
        color: '#b6b7b9',
      },
      subMenuContent: ({ level }) => ({
        backgroundColor:
          level === 0
            ? hexToRgba(themes[theme].menu.menuContent, 1)
            : 'transparent',
      }),
      button: {
        [`&.${menuClasses.disabled}`]: {
          color: themes[theme].menu.disabled.color,
        },
        '&:hover': {
          backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 1),
          color: themes[theme].menu.hover.color,
        },
        '&.ps-active': {
          backgroundColor: hexToRgba(themes[theme].menu.active.backgroundColor, 1),
          color: themes[theme].menu.active.color,
        },
      },
      label: ({ open }) => ({
        fontWeight: open ? 600 : undefined,
      }),
    };

    const unsetOrg = () => {
        navigate('/universities');
        dispatch(resetOrganization());
    };
    
    return (
        <>
            <Sidebar
                collapsed={collapsed}
                toggled={toggled}
                onBackdropClick={() => setToggled(false)}
                onBreakPoint={setBroken}
                image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
                breakPoint="md"
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                rootStyles={{
                    color: themes[theme].sidebar.color,
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    {
                        organization?.name && (
                            <SidebarHeader 
                                style={{ marginBottom: '24px', marginTop: '16px' }}
                                collapsed={collapsed}
                                org={organization}
                                menuItemStyle={{icon: themes[theme].menu.icon, menuContent: themes[theme].menu.hover.color}}
                            />
                        )
                    }
                    <div style={{ flex: 1, marginBottom: '32px' }}>
                        <div style={{ padding: '0 24px', marginBottom: '8px' }}></div>
                        
                        <Menu menuItemStyles={menuItemStyles}>
                            <GeneralSidebarMenu />
                        </Menu>

                        <div style={{ padding: '0 24px', marginBottom: '8px', marginTop: '32px' }}>
                            <Typography
                                variant="body2"
                                fontWeight={600}
                                style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
                            >
                                Extra
                            </Typography>
                        </div>

                        {
                            organization?.id && (
                                <Menu menuItemStyles={menuItemStyles}>
                                    <MenuItem icon={<ExitToAppIcon />} onClick={unsetOrg}>Universités</MenuItem>
                                </Menu>
                            )
                        }
                        
                        <Menu menuItemStyles={menuItemStyles}>
                            <MenuItem 
                                icon={
                                    <Switch
                                        id="theme"
                                        checked={theme === 'dark'}
                                        onChange={handleThemeChange}
                                    />
                                }
                                >
                                Mode Sombre
                            </MenuItem>
                            <MenuItem
                                onClick={() => setCollapsed(!collapsed)}
                                icon={collapsed && <KeyboardDoubleArrowRightIcon />}
                            >
                                {!collapsed && 
                                        <Box display="flex" justifyContent="end">
                                        <IconButton onClick={() => setCollapsed(!collapsed)}>
                                            <KeyboardDoubleArrowLeftIcon sx={{color: themes[theme].menu.icon}} />
                                        </IconButton>
                                    </Box>
                                }
                            </MenuItem>
                        </Menu>
                    </div>
                    
                    {!collapsed ? 
                        (
                            <div style={{display: 'flex'}}>
                                <img src={guineeFlag} width="20%" height="80%" style={{paddingLeft: '1rem'}}/>
                            </div>
                        ): (
                            <div style={{display: 'flex', justifyItems: 'center'}}>
                                <img src={guineeFlag} width="20%" height="80%" style={{paddingLeft: '1rem'}}/>
                            </div>
                        )
                    }
                </div>
            </Sidebar>
        </>
    );
}

export default Component;