import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Step, StepLabel, Stepper, TextareaAutosize, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IVerificationRequest, IVerificationResponse } from "../../entities/organization";
import { AppDispatch, IState } from "../../lib/store";
import { processVrequest } from "../../lib/store/organization/thunks";
import { Container } from "./index.style";
import { IProject } from '../../entities';
import { getCertificatesByFilters } from '../../lib/store/projects/thunks';
import { ICertificate } from '../../entities/certificates';
import Table from '../Table';
import { Head } from '../CertificateIneTable/Head';
import { Body as BodyTable } from '../CertificateIneTable/Body';
import { certificatesTableDataMapper, getCertificateQrcodeValue } from '../../lib/helper';
import Toolbar from '../CertificateIneTable/Toolbar';
import { ICertificateData } from '../../entities/table';
import ConfirmationDialog from '../ConfirmationDialog';
import VerificationResponseTemplate from '../html-templates/VerificationResponseTemplate';
import { MAPPING_METADATA_KEYS_TO_LABEL } from '../../lib/referentiel';

const steps = ['Rechercher', 'Filter', 'Terminer'];

const SelectProject: React.FC<{setProjectID: (id: string) => void, projects: Record<string, IProject>}> = ({setProjectID, projects}) => {
    const handleChange = (event: SelectChangeEvent) => {
      setProjectID(event.target.value);
    };
    return (
      <FormControl sx={{ m: 1, minWidth: '40%' }} >
        <InputLabel id="demo-select-small-label">Specialité</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          label="Template"
          onChange={handleChange}
        >
          {
              Object.entries(projects)?.map(([id, p]) => (
                  <MenuItem key={id}  value={id}>{p.name}</MenuItem>                
              ))
          }
        </Select>
      </FormControl>
    );
}

const getCertificateByHash = (hash: string, certs: ICertificate[]): ICertificate => certs.find((c) => c.hash === hash)!

const Body: React.FC<{vReq: IVerificationRequest, submit: (v: Record<string, any>) => void}> = ({vReq, submit}) => {
    const projectLoading = useSelector((state: IState) => state.projects.loading, shallowEqual);
    const dispatch = useDispatch<AppDispatch>();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = React.useState<{[k: number]: boolean}>({});
    const [certificates, setCertificates] = useState<ICertificate[]>();
    const [certificate, setCertificate] = useState<ICertificate>();

    const [reason, setReason] = React.useState<string>();

    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => {
        setCompleted({...completed, [activeStep - 1]: false});
        setCertificate(undefined);
        setActiveStep(activeStep - 1);
    }

    const onSelect = (cert: ICertificateData) => {
        setCertificate(getCertificateByHash(cert.hash!, certificates!));
        setCompleted({...completed, [1]: true})
        handleNext();
    }

    const loadCertificates = () => {
        dispatch(getCertificatesByFilters({matricule: vReq.matricule || '', ine: vReq.ine || ''})).unwrap()
            .then((certificates) => {setCertificates(certificates); setCompleted({...completed, [0]: true}); handleNext()})
            .catch(() => toast.error('une erreur est survenue lors du chargement des certificats. Veuillez contacter le support pour plus d\infos.'));
    }

    return (
        <Box height="100%" width="100%">
            <Stepper nonLinear activeStep={activeStep} sx={{width: '100%'}}>
                {steps.map((label, index) => (
                    <Step 
                        key={label}
                        completed={completed[index]}
                        sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                              color: '#386088', // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                              color: '#386088', // circle color (ACTIVE)
                            },
                          }}
                    
                    >
                        <StepLabel>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box height="90%" display="flex" flexDirection="column" justifyContent="space-between" p={3}>
                <Box flexGrow={1}>
                    {activeStep === 0 && (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={3}>
                            {/* <SelectProject setProjectID={setProjectID} projects={projects}/> */}
                            <LoadingButton  loading={projectLoading} variant="outlined" color="inherit" onClick={loadCertificates}> Rechercher </LoadingButton>
                        </Box>
                    )}

                    {activeStep === 1 && (
                        <Table 
                            Head={Head}
                            Body={BodyTable}
                            rows={certificatesTableDataMapper(certificates || [])}
                            Toolbar={Toolbar} 
                            variant='B'
                            onRowClick={() => {}}
                            onRowSelect={(selected: any[]) => onSelect(selected[0] as unknown as ICertificateData)}
                        />
                    )}


                    {activeStep === 2 && (
                        <Box  height="100%">
                            
                            {certificate ? 
                            (
                                <>
                                    <Typography textAlign="center">Information détaillée</Typography>
                                    <Box display="flex" flexDirection="column" alignItems="center" height="90%" gap={3} mt={2}>
                                        <Box display="flex" flexDirection="column" textAlign="left">
                                            {
                                                Object.entries(certificate?.metadata || {}).filter(([key, _]) => Object.keys(MAPPING_METADATA_KEYS_TO_LABEL).includes(key)).map(([key, value], idx) => 
                                                    <span key={idx} style={{fontSize: '15px'}}> <strong style={{ color: '#70add6'}}>{MAPPING_METADATA_KEYS_TO_LABEL[key]}</strong>: {value}</span>
                                                )
                                            }
                                        </Box>
                                        <LoadingButton onClick={() => submit({valid: true, certificateLocation: getCertificateQrcodeValue(certificate.hash!)})} color="success" variant="outlined">Confirmer l'authenticité du diplôme demandé</LoadingButton>                      
                                    </Box>
                                </>
                            ): (
                                <Box display="flex" flexDirection="column" alignItems="center" height="100%" gap={3} mt={2}>
                                    <Typography textAlign="center" color="red">Certificat non trouvé</Typography>
                                    <TextareaAutosize
                                        color="info"
                                        value={reason}
                                        style={{width: '80%', height: '40%', textAlign: 'center'}}
                                        onChange={(event) => setReason(event?.target.value)}
                                        placeholder='Entrez la ou les raisons personalisées'
                                    />
                                    <LoadingButton variant="outlined" onClick={() => submit({valid: false, reason})} color="success">Envoyer la réponse</LoadingButton>                      
                                </Box>
                            )}
 
                        </Box>   
                    )}
                </Box>
                <Box display="flex" justifyContent="space-between" mb={1} mt={1}>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant='outlined'
                    >
                        Précèdent
                    </Button>

                    <Button
                        color="inherit"
                        disabled={activeStep === steps.length - 1}
                        onClick={handleNext}
                        variant='outlined'
                    >
                        Continuer
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


const Component: React.FC<{vReq: IVerificationRequest, openModal: boolean, closeModal: () => void}> = ({vReq, openModal, closeModal}) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [response, setResponse] = React.useState<Record<string, any>>();
    const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const sendResponse = () => {
        setLoading(true);
        dispatch(processVrequest({...response, requestID: vReq.id}))
        .unwrap()
            .then(() => toast.success('Reponse envoyée avec succès'))
            .catch(() => toast.error('Une erreur est survenue lors de l\'envoi'))
            .finally(() => {
                setLoading(false);
                setOpenConfirmationDialog(false);
                closeModal();
            });
    }

    return (
        <div style={{width: '100%'}}>
            <Modal
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Container className='color-chart'>
                    <div style={{textAlign: 'end', width: '100%'}}><IconButton color="error" onClick={closeModal} ><CloseIcon /></IconButton></div>
                    <div style={{textAlign: 'center'}}>
                        <Typography fontWeight="bold">Repondre à la demande de {vReq?.requesterName}</Typography>
                    </div>
                    <div 
                        style={
                            {display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                            width: '100%',
                            marginTop: '2rem'
                        }}>
                        <Body vReq={vReq} submit={(v: Record<string, any>) => {setResponse(v); setOpenConfirmationDialog(true)}} />
                    </div>
                </Container>
                
            </Modal>

            <ConfirmationDialog
                open={openConfirmationDialog}
                close={() => setOpenConfirmationDialog(false)}
                loading={loading}
                actionLabel="Envoyer la réponse"
                alignLabel='center'
                contentText="Vous êtes sur le point d'envoyer votre réponse"
                action={sendResponse}
            >
                <VerificationResponseTemplate vReq={vReq} vRep={response as IVerificationResponse} />
            </ConfirmationDialog>
        </div>
    );
}

export default Component;