import { EN_ATTENTE, roleItems } from './../referentiel/index';
import { IProfile } from './../../entities/profile/index';
import { ICertificateData, IRoleData, Order } from './../../entities/table/index';
import { ICertificate } from './../../entities/certificates/index';
import { IProject } from '../../entities';
import { VERIFIER_APP } from '../../config/environment.dev';
import { IOrganization } from '../../entities/organization';
import { ROLES } from '../role-handler';
import { ISession } from '../../entities/verification';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

// todo: refacto with strong typing
// a: { [key in Key]: number | string },
// b: { [key in Key]: number | string },
export function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: any,
    b: any,
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}


var monthNames = [
  "janvier", "février", "mars", "avril", "mai", "juin",
  "juillet", "août", "septembre", "octobre", "novembre", "décembre"
];


export function dateToLocalString(date: string) {
  const splittedDate = date.split('/');
  return splittedDate[0] + " " + monthNames[parseInt(splittedDate[1]) - 1] + " " + splittedDate[2];
}

export function formatDate(dateNumber: number|string) {
  if (!dateNumber || dateNumber === 0) {
    return ""
  }
  const date = new Date(dateNumber);
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export enum STATUS {
  CERTIFIED = 'certifié',
  PRECERTIFIED = 'pré-certifié',
  PENDING = 'en cours',
  MISSIND_DATA = 'données maquantes',
  NOT_FOUND = 'introuvable'
}

export const getStatusLabel = (id: string) => {
  switch(id) {
    case 'certified':
      return STATUS.CERTIFIED.toString();
    case 'pre-certified':
      return STATUS.PRECERTIFIED.toString();
    case 'pending':
        return STATUS.PENDING.toString();
    case 'missing-data':
      return STATUS.MISSIND_DATA.toString();
    default:
      return STATUS.NOT_FOUND.toString();
  }
}

export const getStatusColor = (id: string) => {
  switch(id) {
    case 'certified':
      return 'rgb(64 145 97)';
    case 'pre-certified':
      return 'rgb(180 184 188)';
    case 'pending':
        return 'rgb(237, 108, 2)';
    case 'missing-data':
      return 'red';
    default:
      return '#f6bab3';
  }
}

const isAdmin = (role: string | undefined) => ROLES.ADMIN === role;

export const certificatesTableDataMapper = (certificates: ICertificate[]): ICertificateData[] => {
  return certificates.map((c) => ({
    status: getStatusLabel(c.status!),
    date: formatDate(c.issuingDate * 1000),
    user: c.createdBy!,
    color: getStatusColor(c.status),
    projectID: c.projectID,
    hash: c.hash || "",
    firstname: c?.metadata && c.metadata['prenoms'],
    lastname: c?.metadata && c.metadata['nom'],
    ine: c.ine,
    speciality: c.speciality,
    grade: c.grade,
    annee_obtention: c?.metadata && c.metadata['annee_obtention'],
    matricule: c?.metadata && c.metadata['matricule'],
    institution: c?.metadata && c.metadata['institution'],
    birthDate: c?.metadata && c.metadata['date_naiss'],
    printed: c.printed
  }));
}

export const batchVerificationTableDataMapper = (requests: Record<string, string>[]): ICertificateData[] => {
  return requests.map((c) => ({
    id: c['id'],
    status: getStatusLabel(c['status']!),
    color: getStatusColor(c['status']!),
    hash: c['hash'] || "",
    firstname: c['prenoms'],
    lastname: c['nom'],
    grade: c['grade'],
    annee_obtention: c['annee_obtention'],
    matricule: c['matricule'],
    certificateLink: getCertificateQrcodeValue(c['hash']),
    institution: c['institution'],
    user: "",
    projectID: "",
    reference: c['ref_fp'],
    birthDate: c['date_naiss']
  }));
}

export const rolesTableDataMapper = (members: IProfile[]): IRoleData[] => {
  return members.map((m) => ({
    ...m,
    university: isAdmin(m.roles![0]) ? 'toutes' : m.organization,
    role:  roleItems.find((it) => it.id === (m && m.roles && m.roles[0]))?.label,
    joinAt: formatDate(m.joinAt!) || EN_ATTENTE,
  })) as IRoleData[];
} 

export const getProject = (prId: string, projects: IProject[]): IProject => {
  const project = projects.find(({id}) => id === prId) as IProject;
  return project;
}

export const getCertificate = (hash: string, certificates: ICertificate[]): ICertificate => {
  return certificates.find((c) => c.hash === hash) as ICertificate;
}

export const getQrcodeValue = (slug: string) => (hash: string) => `${VERIFIER_APP}${slug}=${hash}`;

export const getCertificateQrcodeValue = getQrcodeValue('/check?id');
export const getDiplomaQrcodeValue = getQrcodeValue('?diplomaID');

const matchList: {match: string, value: string}[] =     [
  {match: 'licence', value: 'DE LICENCE'},
  {match: 'master', value: 'DE MASTER'},
  {match: 'agro', value: 'D\'INGENIEUR AGRONOME'}
];

export const resolveGrade = (grade: string) => {
  for (let i = 0; i <= matchList.length - 1; i++) {
      if (grade.toLocaleLowerCase().includes(matchList[i].match)) {
          return matchList[i].value;
      }
  }

  return grade;
}

export const getOrgByID = (orgs: IOrganization[], id: string) => orgs.find((p) => p.id === id)!;

export const undefinedSigleLabel = 'SIGLE-INDEFINI';
export const getSigle = (university: string) => {
  const tmp = university?.split('-');
  return tmp?.length > 1 ? tmp.filter((s) => s !== ' ')[0].trim() : undefinedSigleLabel;
}

const rand = (): string => {
  return  Math.floor(Math.random() * Date.now()).toString().slice(1, 11);
}

export const getDiplomaSerialNumber = (university: string): string => {
  const sigle = getSigle(university);
  return sigle.toUpperCase() +'-'+ rand();
}

export const getSessionByID = (sessions: ISession[], id: string): ISession => sessions.find(s => s.id === id)!;

export const computePercent = (nb: number, total: number) => Math.round(nb * 100 / total);

export const findIndexFunc = (selected: readonly ICertificateData[], row: ICertificateData) => selected.findIndex(r => row.hash === r.hash);

const fonts: {label: string, url: string, fallback?: boolean}[] = [
  {
    label: 'Roboto',
    url: 'Roboto/Roboto-Regular.ttf',
    fallback: true
  },
  {
    label: 'Roboto-Italic',
    url: 'Roboto/Roboto-Italic.ttf',
  },
  {
    label: 'Roboto-Bold',
    url: 'Roboto/Roboto-Bold.ttf',
  },
  {
    label: 'Bitter',
    url: 'Bitter/Bitter-Regular.ttf',
  },
  {
    label: 'Bitter-Italic',
    url: 'Bitter/Bitter-Italic.ttf',
  },
  {
    label: 'Bitter-Bold',
    url: 'Bitter/Bitter-Bold.ttf',
  },
  {
    label: 'Lora',
    url: 'Lora/Lora-Regular.ttf',
  },
  {
    label: 'Lora-Italic',
    url: 'Lora/Lora-Italic.ttf',
  },
  {
    label: 'Lora-Bold',
    url: 'Lora/Lora-Bold.ttf',
  }
]

export const fetchFonts = () => {
  return Promise.all(fonts.map(async (f) => {
     return ({
      [f.label]: {
        data: await fetch(`/fonts/${f.url}`).then((res) => res.arrayBuffer()),
        fallback: f.fallback
      }
     });
  }));
}

export const removeAccents = (str: string): string => str && str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
