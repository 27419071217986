import { Box, Button, CircularProgress, Paper, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../lib/store";
import { confirmAccount } from "../lib/store/auth/thunks";

export default () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState();
    const dispatch = useDispatch<AppDispatch>();
    const matches = useMediaQuery('(min-width:600px)');

    const id = params.get('id');

    useEffect(() => {
        dispatch(confirmAccount({id: id!!})).unwrap()
            .catch((error) => setError(error))
            .finally(() => setLoading(false))
    }, []);

    const login = () => navigate('/login');
    return (
    <Box height="100vh" width="100%" display="flex" justifyContent="center" alignItems="center" className="color-chart">
        {
            loading ? (
                <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
            ) : (
                error ? (
                    <Typography>Une erreur est survenue, veuillez contacter le support au  <strong> ecertdoc@gmail.com</strong> ....</Typography>
                ) :
                (
                    <Box 
                        component={Paper}
                        elevation={5}
                        height="50%"
                        width={matches ? '30%' : '100%'}
                        m={5}
                        p={3}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Typography variant="h5" mb="1rem" fontWeight="bold">Compte activé ✅</Typography>
                        <Typography mb={2}>Votre email a été vérifié. Veuillez vous connectez en cliquant sur le bouton ci-dessous</Typography>
                        <Button color="inherit" variant="outlined"  onClick={login}>Connexion</Button>
                    </Box>
                )
            )
        }
    </Box>
    )
}