import { Box, Paper, Typography } from "@mui/material";

const DashBoardCard1: React.FC<{Icon: React.ReactElement, text1: string, text2: number}> = ({Icon, text1, text2}) => {
    return (
        <Box
            component={Paper}
            elevation={1}
            display="flex"
            justifyContent="space-between"
            padding={2}
            width="40%"
            bgcolor="rgb(68, 89, 110)"
            color="#FFFF"
            >
            {Icon}
            <Box textAlign={'right'} display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="body2">{text1}</Typography>
            <Typography variant="h6" fontWeight="bold">{text2}</Typography>
            </Box>
        </Box>
    )
}

export default DashBoardCard1;
  