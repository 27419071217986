import Axios from 'axios';
import { LOCALSTORAGE_KEYS } from '../referentiel';
import { setupCache } from 'axios-cache-interceptor';

// export const client = setupCache(Axios); 
export const client = Axios.create();

const protectedUrls = [
    'user/profile',
    'organization',
    'project',
    'pre-certify',
    'certify',
    'file',
    'batch-precertify',
    'verification-request',
    'certificate',
    'update',
    'certificate/printed',
    'certificate/config',
    'user/password'
];

const isProtected = (url: string): boolean => {
    return protectedUrls.some((path) => url.includes(path));
}

client.interceptors.request.use((config) => {
    const token = localStorage.getItem(LOCALSTORAGE_KEYS.TOKEN);
    if (isProtected(config.url!)) {
        config.headers!['Authorization'] =  `Bearer ${token}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});