import { Template } from '@pdfme/common';
import { barcodes, text } from '@pdfme/schemas';
import { Form } from "@pdfme/ui";
import { useEffect, useRef, useState } from "react";
import { useTemplateFonts } from '../../lib/contexts/TemplateFonts';

interface IProps {
    template: Template;
    inputs: Record<string, string>[];
    setForm: (form: Form) => void;
}

const Component: React.FC<IProps> = ({template, inputs, setForm}) => {
    const formRef = useRef<HTMLDivElement | null>(null);
    const form = useRef<Form | null>(null);
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const templateFonts = useTemplateFonts();

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            return;
        }

        if (formRef.current) {
            try {
                form.current = new Form({
                    domContainer: formRef.current!,
                    template,
                    inputs: inputs || template.sampledata || [{}],
                    plugins: {
                        text,
                        qrcode: barcodes.qrcode
                    },
                    options: {
                        font: templateFonts.fonts
                    }
                });

                setForm(form.current);
            } catch(e) {
                console.log(e)
            }
        }
    }, [firstRender]);

    
    return (
        <div
            ref={formRef}
            style={{ width: '100%', height: '100%'}}
        />
    )
}

export default Component;