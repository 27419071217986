
import LockIcon from '@mui/icons-material/Lock';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Badge, Box, IconButton, ListItemIcon, Menu, MenuItem, Paper, Typography } from "@mui/material";
import * as React from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import logoMesrsi from "../../assets/images/logo-MESRSI.png";
import { dateToLocalString, formatDate } from '../../lib/helper';
import { AppDispatch, IState } from "../../lib/store";
import { logOut } from '../../lib/store/auth/thunks';
import EditPassword from '../EditPassword';
import OperatorComponent from '../RolesComponent/Operator';
import VerificationRequestDrawer from "../VerificationRequestDrawer";
import { Toolbar } from "./index.style";
interface IProps {
    handleDrawerToggle: () => void;
    broken: boolean;
}

const CustomAppBar: React.FC<IProps> = ({broken, handleDrawerToggle}) => {
    const vRequests = useSelector((state: IState) => state?.organization?.organization?.verificationRequests, shallowEqual)!;
    const [openVrequests, setOpenVrequests] = React.useState<boolean>(false);
    const currentUser = useSelector((state: IState) => state?.auth?.profile);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openEditPassword, setOpenEditPassword] = React.useState<boolean>(false);

    const open = Boolean(anchorEl);

    const handleProfileMenuClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
      setAnchorEl(null);
    };

    const pendingVreq = React.useMemo(() => {
        return vRequests?.filter(({status}) => status === 'pending');
    }, [vRequests]);

    const _logOut = () => {
        dispatch(logOut());
    }

    return (
        <Box component={Paper} elevation={0} position={'static'}>
            <Toolbar
                sx={{
                    display: 'flex',
                }}
                className='color-chart'
            >
                <div style={{flex: 1}}>
                    {
                        broken ? (
                            <IconButton color="inherit" onClick={handleDrawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <img src={logoMesrsi} style={{width: '33%'}}   />
                        )
                    }
                </div>

                <div>
                    <span style={{fontSize: '13px', paddingRight: '1rem', marginRight: '1rem', borderRight: 'solid 2px', color: '#607489'}}>{dateToLocalString(formatDate(Date.now()))}</span>
                    <span style={{fontSize: '13px', color: '#607489',  paddingRight: '1rem', marginRight: '1rem', borderRight: 'solid 2px',}}>{currentUser?.firstname} {currentUser?.lastname}</span>
                    <IconButton color="inherit" onClick={handleProfileMenuClick}>
                        <PermIdentityIcon sx={{fontSize: '14x', color: '#607489'}} color='inherit' />
                    </IconButton>
                </div>

                {
                    !location.pathname.includes('universities') && pendingVreq?.length > 0 && (
                        <OperatorComponent>
                            <Box display="flex">
                                <IconButton onClick={() => setOpenVrequests(true)}>
                                    <Badge badgeContent={pendingVreq?.length} color="secondary" invisible={pendingVreq?.length === 0}>
                                        <NotificationAddOutlinedIcon sx={{color: '#607489'}} />
                                    </Badge>
                                </IconButton>
                            </Box>
                        </OperatorComponent>
                    )
                }
            </Toolbar>
            <VerificationRequestDrawer open={openVrequests} close={() => setOpenVrequests(false)} vRequests={vRequests}/>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleProfileMenuClose}
                onClick={handleProfileMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => setOpenEditPassword(true)}>
                        <ListItemIcon>
                            <LockIcon fontSize="small" sx={{color: '#607489'}} />
                        </ListItemIcon>
                        <Typography variant='body2' sx={{color: '#607489', fontSize: '13px'}}>Modifier mot de passe</Typography>
                    </MenuItem>
                    <MenuItem onClick={_logOut}>
                        <ListItemIcon>
                            <PowerSettingsNewIcon fontSize="small" sx={{color: '#607489'}} />
                        </ListItemIcon>
                        <Typography variant='body2' sx={{color: '#607489', fontSize: '13px'}}>Déconnexion</Typography>
                    </MenuItem>
            </Menu>

            <EditPassword
                open={openEditPassword}
                close={() => setOpenEditPassword(false)}
            />
        </Box>
    )
}

export default CustomAppBar;