import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@mui/material";
import XLSX from 'xlsx-js-style';
import { ICertificateData } from "../../entities/table";
import { STATUS } from "../../lib/helper";
interface IProps {
    certificates: ICertificateData[];
}
const withColor = (curr: any[]) => curr.map((value) => 
    ({
        v: value,
        s: {
            fill: {
                patternType: 'solid',
                fgColor: { rgb: 'FF3C00' },
                bgColor: { rgb: 'FF3C00' },
            }
        }
    }))

const withoutColor = (curr: any[]) => curr.map((value) => ({ v: value }));

const DownloadCertificates: React.FC<IProps> = ({certificates}) => {
    const download = () => {
        const data = (certificates as any).reduce((acc: any, curr: any) => {
            if (curr.status === STATUS.NOT_FOUND.toString()) {
                return [...acc, [...withColor(Object.values(curr))]] 
            }
            return [...acc, [...withoutColor(Object.values(curr))]] 
        }, []);

        const excelHeader = [
            "Statut",
            "Prénoms",
            "Nom",
            "Diplôme",
            "Année d\'obtention",
            "Matricule",
            "Certificat",
            "Institution",
            "Référence",
            "Date de naissance",
        ];

        const worksheet = XLSX.utils.aoa_to_sheet([excelHeader, ...data]);
        let wscols: any[] = [];

        Object.keys(excelHeader).map((c) => {
            wscols.push({wch: 20});
        })

        worksheet["!cols"] = wscols as any;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Diplômes");
        XLSX.writeFile(workbook, "Liste_diplomes.xlsx", { compression: true });
    }

    return (
        <Button variant="outlined" color="inherit"
            onClick={download}
        >
            <DownloadIcon  />
        </Button>
    )
}

export default DownloadCertificates;