import { TableCell, TableRow } from "@mui/material";
import { IProfile } from "../../../entities";
import { IBodyProps, IRoleData } from "../../../entities/table";
import { getComparator } from "../../../lib/helper";
import { EN_ATTENTE } from "../../../lib/referentiel";
import { UserCard } from "../../UserCard";

interface IProps extends IBodyProps<IRoleData> {}

export const Body: React.FC<IProps> = ({rows, order, orderBy, page, rowsPerPage}) => {
    return (
        <>
            {rows.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={`${row.email}_${Math.random()}`}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.joinAt !== EN_ATTENTE ? 
                          (
                            <div style={{display: 'flex'}}>
                              <UserCard user={{
                                firstname: row.firstname,
                                lastname: row.lastname, 
                                email: row.email} as IProfile}
                                variant="A"
                              />
                            </div>
                          ) :
                          (
                            <div style={{display: 'flex'}}>
                              <UserCard user={{
                                firstname: row.firstname,
                                lastname: row.lastname, 
                                email: row.email} as IProfile}
                                variant="C"
                              />
                            </div>
                          )
                        }
      
                      </TableCell>
                      <TableCell> {row.university} </TableCell>
                      <TableCell> {row.role} </TableCell>
                      <TableCell>{row.joinAt}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}