import { Box, Typography, alpha } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import { ICertificateData } from "../../../entities/table";
import SearchBar from "../../SearchBar";

interface IProps {
  itemsSelected?: ICertificateData[];
  rows: ICertificateData[];
  unSelect: () => void;
  setLocalRows: React.Dispatch<React.SetStateAction<any[]>>
  variant?: 'A' | 'B'
}

export default (props: IProps) => {
    const { itemsSelected, unSelect, setLocalRows, rows, variant='A' } = props;

    const onSearchTerm = (term: string) => {
      if (term === "") {
        setLocalRows(rows);
        return;
      };
      setLocalRows(rows.filter((r) => r.grade &&  r.grade.toLocaleLowerCase().includes(term.toLocaleLowerCase())))
    }
    return (
      <>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(itemsSelected?.length! > 0 && {
              bgcolor: (theme) =>
                alpha('#07bc0c', theme.palette.action.activatedOpacity),
            })
          }}
        >

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            mb={3}
            width="100%"
          >
            <Box width="100%">
              <Typography
                width="100%"
                textAlign="center"
              >
                {
                  rows.length > 0 && (
                    <span><strong>Étudiant(e):</strong> {rows[0].firstname} {rows[0].lastname}</span>
                  )
                }
              </Typography>
            </Box>
            <Box width="100%">
              <Typography
                width="100%"
                textAlign="center"
                fontSize={'15px'}
                sx={{opacity: 0.5}}
              >
                Veuillez selectionner le certificat ou cliquer sur "CONTINUER" si vous ne le trouvez pas
              </Typography>
            </Box>
            <Box width="100%" textAlign="center"><SearchBar label="Dilpôme..." onSearch={onSearchTerm}/></Box>
          </Box>
        
        </Toolbar>
      </>
    );
}