import { Box } from "@mui/material"
import Stats from "../components/Stats";

const Component: React.FC = () => {
    return (
        <Box>
            <Stats />
        </Box>
    )
}

export default Component;