import { Box, CircularProgress } from "@mui/material"
import { RolesTable } from "../components/RolesTable"
import { IRoleData } from "../entities/table"
import InviteMemberModal from '../components/InviteMemberModal';
import { useMemo, useState } from "react";
import Toolbar from "../components/RolesTable/Toolbar";
import { IInvitation } from "../entities/organization";
import { shallowEqual, useSelector } from "react-redux";
import { AppDispatch, IState } from "../lib/store";
import { useDispatch } from 'react-redux';
import { inviteMembers } from "../lib/store/organization/thunks";
import { rolesTableDataMapper } from "../lib/helper";
import { toast } from "react-toastify";



export const Roles: React.FC = () => {
    const members = useSelector((state: IState) => state.organization.members || [], shallowEqual);
    const loading = useSelector((state: IState) => state?.organization?.invitationLoading);
    const orgLoading = useSelector((state: IState) => state?.organization?.loading);

    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const handleCancel = () => setOpen(false);
    const onSubmit = (invitations: IInvitation[]) => {
        dispatch(inviteMembers(invitations)).unwrap()
            .then(() => {
                toast.success('invitations envoyés avec succès');
                setOpen(false);
            })
            .catch((err) => {
                console.log(err);
                toast.error('Une erreur est survenue lors de l\'invitation, veuillez contacter le support si le problème persiste...')
            })
    };

    return (
        <Box>
            {
                orgLoading ? (
                     <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
                ) :  (

                    <>
                        <RolesTable rows={rolesTableDataMapper(members!)} Toolbar={() => <Toolbar openInvitationModal={() => setOpen(true)}/>}/>
            
                        <InviteMemberModal open={open} loading={loading} handleCancel={handleCancel} onSubmit={onSubmit} />
                    </>

                )
            }
        </Box>
    )
}