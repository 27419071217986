import { ButtonBase, CircularProgress, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateProjectModal } from "../components/CreateProjectModal";
import { ProjectCard } from "../components/ProjectCard";
import { IProject } from "../entities/projects";
import { AppDispatch, IState } from "../lib/store";
import { createProject } from "../lib/store/projects/thunks";
import { NewProject } from "../lib/toast/NewProject";

const geToData = (path: string[], data: Record<string, any>) => {
    if (path.length === 0) {
        return data;
    }

    let d = data;

    path.map((p: any) => {
        d = d[p];
    });

    return d;
}

export const Project: React.FC = ({}) => {
    const [openModal, setOpenModal] = useState<boolean>();
    const certificates = useSelector((state: IState) => state.projects.certificates, shallowEqual);
    const loading = useSelector((state: IState) => state.projects.loading, shallowEqual);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const matches = useMediaQuery('(min-width:600px)');
    const params = useParams();
    const path = [params['year'], params['grade'], params['program']].filter((p) => !!p) as string[];

    const handleClose = () => {
        setOpenModal(false);
    };

    const onSubmit = (project: IProject, reset: () => void) => {
        dispatch(createProject(project))
            .then(() => {
                toast.success(<NewProject name={project.name!} />);
                setOpenModal(false);
                reset();
            })
    }
    

    return (
        <>
            {
                loading ?
                (   
                    <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
                ):
                (   
                    <>
                        <Divider sx={{ margin: '2rem 0'}}/>
                        <Box width="100%" display="flex">
                            <Box display="flex" flexWrap="wrap" justifyContent={matches ? undefined : 'center'} gap={5}>
                                {!!path && !!certificates &&  Object.keys(geToData(path, certificates)).map((level: string, idx: number) => {
                                    switch(path.length) {
                                        case 1:
                                            return (
                                                <ButtonBase key={idx} onClick={() =>  navigate(level)}>
                                                    <ProjectCard variant="folder" label={
                                                        <div className="color-chart" style={{fontSize: '15px', display: 'flex', flexDirection: 'column'}}>
                                                            <strong>Diplôme: </strong>
                                                            <span style={{color: level.toLowerCase().includes('indefinie') ? 'red' : ''}}>
                                                                {level}
                                                            </span>
                                                        </div>
                                                    }/>
                                                </ButtonBase>
                                            )

                                        case 2:
                                            return (
                                                <ButtonBase key={idx} onClick={() => navigate(level)}>
                                                    <ProjectCard variant="folder" label={
                                                        <span className="color-chart" style={{fontSize: '15px', display: 'flex', flexDirection: 'column'}}>
                                                            <strong>Progamme: </strong>
                                                            <span style={{color: level.toLowerCase().includes('indefinie') ? 'red' : ''}}>
                                                                {level}
                                                            </span>
                                                        </span>
                                                    }/>
                                                </ButtonBase>
                                            )
                                            
                                        default:
                                            return (
                                                <ButtonBase key={idx} onClick={() => navigate(level)}>
                                                    <ProjectCard variant="folder" label={
                                                            <span className="color-chart" style={{fontSize: '15px'}}>
                                                                <strong>Année: </strong>
                                                                <span style={{color: level.toLowerCase().includes('indefinie') ? 'red' : ''}}>
                                                                    {level}
                                                                </span>
                                                            </span>
                                                        }/>
                                                </ButtonBase>    
                                            )
                                    }
                                    })}
                            </Box>
                        </Box>
                    </>
                )
            }

            <CreateProjectModal open={!!openModal} loading={!!loading} handleCancel={handleClose} onSubmit={onSubmit}/>
        </>
    )
}
