import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import React, { useEffect, useState } from 'react';

import BeenhereIcon from '@mui/icons-material/Beenhere';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PrintIcon from '@mui/icons-material/Print';
import { shallowEqual, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CORE_API } from "../../config/environment.dev";
import { IStats } from "../../entities/dashboard";
import { getSigle } from "../../lib/helper";
import { client } from "../../lib/http-client";
import { IState } from "../../lib/store";
import Card1 from "../Dashboard/Card1";
import Card2 from "../Dashboard/Card2";
import ErrorIcon from '@mui/icons-material/Error';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);


interface IProps {
    stats: IStats;
}


// const stats: IStats = {
//     nbCertsByYear: [
//         {
//             year: '2010',
//             nbCertified: 100,
//             nbPrecertified: 200,
//             nbMissingData: 50
//         },
//         {
//             year: '2011',
//             nbCertified: 400,
//             nbPrecertified: 1000,
//             nbMissingData: 200
//         },
//         {
//             year: '2012',
//             nbCertified: 100,
//             nbPrecertified: 400,
//             nbMissingData: 100
//         },
//         {
//             year: '2013',
//             nbCertified: 200,
//             nbPrecertified: 500,
//             nbMissingData: 10
//         },
//         {
//             year: '2014',
//             nbCertified: 600,
//             nbPrecertified: 1000,
//             nbMissingData: 100
//         }
//     ],
//     nbGrade: 5,
//     nbProgram: 20,
//     verifReqStats: {
//         nbPendingReq: 10,
//         nbProcessedReq: 50
//     },
//     memberStats: {
//         nbJoinedMember: 6,
//         nbPendingMember: 2
//     }
// }
  
const getStats = (orgID: string, institution: string) => client.get<IStats>(`${CORE_API}/certificate/stats/${orgID}/${institution}/2022-2023`,).then((res) => res.data);

const Component: React.FC = () => {
  const [stats, setStats] = useState<IStats>();
  
  const [loading, setLoading] = useState<boolean>(true);
  const org = useSelector((state: IState) => state.organization.organization, shallowEqual)!;

  useEffect(() => {
    getStats(org.id!, getSigle(org.name))
      .then(setStats)
      .catch(() => {toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...')})
      .finally(() => setLoading(false))

    setLoading(false)
  }, []);


  return (
    <>
      {
        loading ? <CircularProgress /> : (
          <Box display="flex" flexDirection="column" gap={4} sx={{justifyContent: 'center'}}>
            <Box component={Paper} ><Typography variant="h6" textAlign="center" p={2} sx={{color: 'rgb(68, 89, 110)'}} >Session 2022-2023</Typography></Box>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={3}>
              <Card1 Icon={<LibraryBooksIcon />} text1="Type de diplôme" text2={stats?.nbGrade!} />
              <Card1 Icon={<LibraryBooksIcon />} text1="Total départements" text2={stats?.nbProgram!} />
            </Box>

            <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={3}>
              <Card2
                  Icon={<PrintIcon className="color-chart" />}
                  left={{text: "Total", value: stats?.total!}}
                  right={{text: "Imprimés", value: stats?.nbPrinted!}}
                />

                <Card2
                  Icon={<BeenhereIcon className="color-chart" />}
                  left={{text: "Total", value: stats?.total!}}
                  right={{text: "Certifiés", value: stats?.nbCertified!}}
                />
            </Box>

            <Box display="flex" justifyContent="center">
              <Card2
                Icon={<ErrorIcon color="error" />}
                left={{text: "Total", value: stats?.total!}}
                right={{text: "Ine manquants", value: stats?.nbMissingIne!, color: 'red'}}
              />
            </Box>


            {/* <Box width="100%" display="flex" justifyContent="space-between">
              <Box width='50%' component={Paper} elevation={2} p={1}>
                <Bar nbCertsByYear={stats.nbCertsByYear}/>
              </Box>

              <Box width='30%' component={Paper} elevation={2} p={1} display="flex" justifyContent="center">
                <Pie nbCertsByYear={stats.nbCertsByYear}/>
              </Box>
            </Box> */}
         </Box>
        )
      }
    </>
  );
}

export default Component;