import * as React from 'react';
import Toolbar from './Toolbar';
import { ICertificateData } from '../../entities/table';
import { Head } from './Head';
import { Body } from './Body';
import Table from '../Table';

interface IProps {
  rows: ICertificateData[]
}

export const BatchVerificationTable: React.FC<IProps> = ({rows}) => {
  return <Table Head={Head} Body={Body} rows={rows} Toolbar={Toolbar}/>;
}
