import { TableCell, TableRow } from "@mui/material";
import { IBodyProps, ITemplateData } from "../../../entities/table";
import { getComparator } from "../../../lib/helper";
import { useNavigate } from "react-router-dom";

interface IProps extends IBodyProps<ITemplateData> {}

export const Body: React.FC<IProps> = ({rows, order, orderBy, page, rowsPerPage}) => {
    const navigate = useNavigate();
    return (
        <>
            {rows.slice().sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.name}
                      onClick={() => navigate(`${row.id}`)}
                    >
                      <TableCell>{row.name}</TableCell>
                      <TableCell> {row.author} </TableCell>
                      <TableCell> {row.createdAt} </TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                    </TableRow>
                );
            })}
        </>
    );
}