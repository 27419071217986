import AutorenewIcon from '@mui/icons-material/Autorenew';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, List, ListItem, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { ICertificateData } from "../../entities/table";
import { Container } from "./index.style";
interface IProps {
    open: boolean;
    close: () => void;
    certificates: ICertificateData[];
    submit: () => Promise<void>;
}
export const CertifyActionModal: React.FC<IProps> = ({open, close, certificates, submit}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    const _submit = () => {
        setLoading(true);
        submit()
            .then(() => setShowConfirmation(true))
            .finally(() => setLoading(false))
    }

    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Container gap={2} p={4} className="color-chart">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Certifier
                </Typography>

                {
                    showConfirmation ? (
                        <>
                            <AutorenewIcon color="warning"/>
                            <Typography variant="body1" color="rgb(237, 108, 2)" width="70%" textAlign="center">Inscription des certificats dans la blockchain en cours, vous recevez un mail de confirmation une fois terminée.</Typography>
                            <Button variant="outlined" color="inherit" onClick={() => {setShowConfirmation(false); close()}}>D'accord</Button>
                        </>
                    ): (
                        <Box sx={{overflow: 'scroll'}}>
                            <List sx={{borderStyle: 'groove', borderColor: 'wheat'}}>
                                {certificates.map((c) => (
                                    <ListItem key={c.hash}>{[c.ine, c.grade, c.speciality].join('_')}</ListItem>
                                ))}
                            </List>

                            <Stack direction="row" justifyContent="space-between" p="1rem 7rem">
                                <Button variant="outlined"sx={{right: '1rem'}} onClick={close} color="error">Annuler</Button>
                                <LoadingButton variant="outlined" type="submit" loading={loading} color="success" onClick={_submit}>Valider</LoadingButton>
                            </Stack>
                        </Box>
                    )
                }


            </Container>
        </Modal>
    )
}