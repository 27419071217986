import { Box, Chip, Modal, Stack, TextField, Typography, ButtonBase, Button, Grid, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { Container } from "./index.style"
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { roleItems } from "../../lib/referentiel";
import { IInvitation, IOrganization } from "../../entities/organization";
import { shallowEqual, useSelector } from "react-redux";
import { IState } from "../../lib/store";
import { IProject } from "../../entities";
import AddIcon from '@mui/icons-material/Add';
import { getOrgByID } from "../../lib/helper";

interface IProps {
    open: boolean;
    loading: boolean;
    handleCancel: () => void;
    onSubmit: (newProject: any) => void;
}

interface IInviteProps {
    index: number;
    value: any;
}


const CModal: React.FC<IProps> = ({open, handleCancel, onSubmit, loading}) => {
    const orgs = useSelector((state: IState) => state.organization.orgs, shallowEqual);
    const { control, reset, handleSubmit } = useForm();
    const { fields, append, remove } = useFieldArray<Record<string, any>>(
        {
            control,
            name: "invitations"
        }
    );
    const submit = (form: any) => {
        onSubmit(form['invitations'] as IInvitation[]);
        reset();
    }

    const Invite = (props: IInviteProps) => {
        return (
            <Box sx={{display: 'flex'}}>
                <Grid container gap={1} justifyContent="center" className="color-chart">
                    <Grid item>
                        <Controller
                            name={`invitations[${props.index}].email`}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: {
                                    value: true,
                                    message: "Veuillez entre une adresse email valide"
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: "adresse email invalide"
                                }
                            }}
                            render={({ field,  fieldState: { error } }) => 
                                <FormControl sx={{minWidth: 120 }}>
                                    <TextField size="small" id="demo-helper-text-misaligned-no-helper" label="Email"
                                    error={!!error}
                                    helperText={ error ? <Typography color="error" sx={{fontSize: '10px'}}>{error.message}</Typography> : null}
                                        {...field}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item >
                        <Controller 
                            name={`invitations[${props.index}].role`}
                            control={control}
                            defaultValue=""
                            render={({ field: { onChange, value } }) =>
                                <FormControl sx={{minWidth: 200, maxWidth: 250 }} size="small">
                                    <InputLabel id="demo-select-small">Role</InputLabel>
                                    <Select
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        label="Role"
                                        value={value}
                                        onChange={onChange}
                                    >
                                        {roleItems.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem> )}
                                    </Select>
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item >
                        <Controller
                            name={`invitations[${props.index}].orgIDs`}
                            control={control}
                            defaultValue={[]}
                            render={({ field: { onChange, value } }) =>
                                <FormControl sx={{minWidth: 300, maxWidth: 500 }} size="small">
                                    <InputLabel id="org-select-small">Universitiés</InputLabel>
                                    <Select
                                        multiple
                                        labelId="org-select-small"
                                        id="org-select-small"
                                        label="Universités"
                                        value={value}
                                        renderValue={(selected) => 
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value: string) => (
                                              <Chip key={value} label={getOrgByID(orgs, value).name} />
                                            ))}
                                          </Box>
                                        }
                                        onChange={onChange}
                                    >
                                        {orgs.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem> )}
                                    </Select>
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item sx={{display: 'flex', alignItems: 'center'}}>
                        <Button onClick={() => remove(props.index)} sx={{borderRadius: '2rem', background: '#e6e4e4', padding: 0, minWidth: '10px'}}>
                            <CloseIcon sx={{color: '#c0b2b2'}} />
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', overflow: 'scroll'}}
            open={open}
            onClose={handleCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Container className="color-chart">
                <div style={{textAlign: 'center', padding: '1rem'}}>
                    <Typography sx={{fontWeight: 'bold'}}>Invitez des membres</Typography>
                    <Typography sx={{fontSize: '10px'}}>Envoyez des liens d'invitations aux membres de votre equipe</Typography>
                </div>
                <form style={{width: '100%'}} onSubmit={handleSubmit(submit)}>
                    <Box display="flex" flexDirection="column" gap={2} p="1rem 1rem">
                        {fields && fields.map((field, idx) => {
                            return (
                                <Invite key={field.id} index={idx} value={field}/>
                            )
                        })}
                        <Box display="flex" justifyContent="center">
                            <Stack direction="row" justifyContent="space-between" p="1rem 1rem" width="50%">
                                <Button variant="outlined" color="inherit" sx={{right: '1rem'}} onClick={() =>{ append({email: '', role: '', orgIDs: []})}}>
                                    <Typography style={{fontSize: '10px', color: 'gray'}}>
                                        <AddIcon /> Ajouter un nouveau membre
                                    </Typography>
                                </Button>
                                <LoadingButton variant="outlined" type="submit" loading={loading} color="success">
                                    <SendIcon />
                                </LoadingButton>
                            </Stack>
                        </Box>
                    </Box>
                </form>
            </Container>
        </Modal>
    )
}
export default CModal;