import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import bannier from "../../assets/images/bannier-2.png";

interface IProps {
    Left: React.FC;
    Right: React.FC;
    styleLeft?: any;
    styleRight?: any;
    paperStyle?: any;
    showBannier?: boolean;
}

export const SplittedView: React.FC<IProps> = ({showBannier=true, Left, Right, styleLeft, styleRight={overflow: 'scroll'}, paperStyle}) => {
    const matches = useMediaQuery('(min-width:700px)');
    return (
        <Box height="100vh" width="100%">
            {!matches && (<img src={bannier} height='100px' width="100%"/> )}
            <Box height="100%" width="100%" className="splitted-view">
                <Grid container component={Paper}  elevation={0} height="80%" width="100%"  m={5} sx={paperStyle}>
                    <Grid sx={{display: matches ? 'block' : 'none'}} className="grid-item-1" item lg={6} md={6} width="100%" height="100%" style={styleLeft}>
                        {showBannier && (<img src={bannier} height='100px' width="100%"/>)} 
                        <Left />
                    </Grid>
                    <Grid className="grid-item-2" item lg={6} md={6} width="100%" height="100%" sx={styleRight} style={{overflowX: 'hidden'}}>
                        <Right />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}