import { Template } from '@pdfme/common';
import { barcodes, text } from '@pdfme/schemas';
import { Viewer } from "@pdfme/ui";
import { useEffect, useRef, useState } from "react";
import { useTemplateFonts } from '../../lib/contexts/TemplateFonts';

interface IProps {
    template: Template;
    inputs: Record<string, string>[]
}

const Component: React.FC<IProps> = ({template, inputs}) => {
    const viewerRef = useRef<HTMLDivElement | null>(null);
    const viewer = useRef<Viewer | null>(null);
    const [firstRender, setFirstRender] = useState<boolean>(true);
    const templateFonts = useTemplateFonts();

    useEffect(() => {

        if (firstRender) {
            setFirstRender(false);
            return;
        }

        if (viewerRef.current) {
            try {
                viewer.current = new Viewer({
                    domContainer: viewerRef.current!,
                    template,
                    inputs: inputs || template.sampledata || [{}],
                    plugins: {
                        text,
                        qrcode: barcodes.qrcode
                    },
                    options: {
                        font: templateFonts.fonts
                    }
                });
            } catch(e) {
                console.log(e)
            }
        }
    }, [firstRender]);

    
    return (
        <div
            ref={viewerRef}
            style={{ width: '100%', height: '100%'}}
        />
    )
}

export default Component;