import { Box, Button, TextField, useMediaQuery } from "@mui/material";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { SplittedView } from "../components/SplittedLayout";
import organizationIMG from "../assets/images/new_univ_2.png";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppDispatch, IState } from '../lib/store';
import { useDispatch, useSelector } from "react-redux";
import { createNewOrganization } from "../lib/store/organization/thunks";
import { ICreateOrganization, IOrganization } from "../entities/organization";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { me } from "../lib/store/auth/thunks";
import { useEffect, useState } from "react";
import armoirie from "../assets/images/armoirie.png";

interface IProps {

}

export const NewOrganization: React.FC<IProps> = () => {
    const loading = useSelector((state: IState) => state.organization.loading);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [logo, setLogo] = useState<File>();
    const [isSafari, setIsSafari] = useState<boolean>(false);
    const matches = useMediaQuery('(min-width:800px)');

    const { register, handleSubmit, formState: { errors } } = useForm();
 
    const submit = (value: any) => {
        console.log(value);
        dispatch(createNewOrganization({...value, logo} as ICreateOrganization)).unwrap()
            .then(() => toast.success('Espace université créé avec succès', {delay: 2}))
            .then(() => dispatch(me()))
            .catch(() => toast.error('une erreur est survenue, veuillez re-essayer. Si le problème persiste contactez nous !'));
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files || [];
        if (files.length > 0) {
            setLogo(files[0]);
        }
    };

    useEffect(() => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafari)
    }, []);
    
    return (
        <SplittedView
            showBannier={false}
            Left={() => (<img src={organizationIMG} width="100%" height="100%"/>)}
            paperStyle={{backgroundColor: '##efefef'}}
            Right={() => (
                <div style={{padding: '2rem', width: isSafari ? undefined : matches ? undefined : '100%'}} className="color-chart">

                    <Box display="flex" justifyContent="space-between" className="register">
                        <Typography fontWeight="bold" >Création espace université</Typography>
                        <img width="20%" src={armoirie} />
                    </Box>
                    <form onSubmit={handleSubmit(submit)}
                         style={{display: 'flex', flexDirection: 'column', padding: '1rem'}}> 
                        <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="Nom" {...register('name', {required: true})}/>
                        {errors.name && <Typography color="error" sx={{fontSize: '10px'}}>Veuillez entrer le nom du projet.</Typography>}
                        {/* <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="Adresse"  {...register('address')}/> */}
                        <TextField variant="standard" style={{marginTop: '1rem'}} id="demo-helper-text-misaligned-no-helper" label="Lien du site web"  {...register('websiteLink')}/>
                        <Button variant="outlined" color={logo ? 'success' : 'secondary'} style={{marginTop: '1rem'}}>
                            <label htmlFor="logo">
                                {logo ? 'changer le logo' : 'ajouter un logo'}
                                <input id="logo" type="file" name="myImage" accept="image/png, image/gif, image/jpeg" style={{display: 'none', width: '100%'}} onChange={onChange}/ >
                            </label>
                        </Button>
                        <div style={{marginTop: '2rem', width: '100%', alignItems: 'center'}}>
                            <LoadingButton style={{width: '50%'}} color="inherit" variant="outlined" type="submit" loading={loading}>Créer</LoadingButton>
                        </div>
                    </form>
                </div>
            )}
        />
    );
}