import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { IState } from "../../lib/store";
import { Box } from "@mui/material";

interface IProps {
}
export const ProtectedRoute: React.FC<IProps> = () => {
    const isAuth = useSelector((state: IState) => state?.auth?.authenticated);
    const userLoading = useSelector((state: IState) => state?.auth?.loading);
    const organizationLoading = useSelector((state: IState) => state?.organization?.loading);
    const orgs = useSelector((state: IState) => state?.organization?.orgs);
    const organisation = useSelector((state: IState) => state?.organization?.organization?.id);

    if (userLoading || organizationLoading) {
        return <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>;
    }
    
    if  (!userLoading && !isAuth) {
        console.log('to login')
        return <Navigate to="/login" />;
    }

    if (!userLoading && !organizationLoading && orgs.length === 0) {
        return <Navigate to="/new-organization" />;
    }

    if (!userLoading && orgs.length > 0 && !organisation && !organizationLoading) {
        return <Navigate to="/universities" />;
    }

    return ((userLoading || organizationLoading) ? <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center"><CircularProgress sx={{color: '#005e7a'}} /></Box> : <Outlet />);
}