import { Box, Chip, Paper, Stack, Typography } from "@mui/material";
import { IProject } from "../../entities";
import { Container } from "./index.style";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { STATUS, getStatusColor } from "../../lib/helper";
import FolderIcon from '@mui/icons-material/Folder';
// Project description Project description Project description Project description Project descriptionProjectdescriptionPr
interface IProps {
    project?: IProject;
    label?: React.ReactElement;
    variant?: 'empty' | 'full' | 'folder';
}
export const ProjectCard: React.FC<IProps> = ({project={}, variant='full', label}) => {
    return (
        <>
            {variant == 'full' &&
                (
                    <Container component={Paper} elevation={5} p={1}>
                        <Box display="flex" justifyContent="center" alignItems="center" gap={3} flexDirection="column">
                            <Typography variant="h6">{project.name}</Typography>
                            <Typography sx={{fontSize: '0.7rem'}}>{project.description}</Typography>
                            <Stack direction="row" spacing={2}>
                                <Chip label={`${STATUS.CERTIFIED}: ${project.numberOfDoneCertificates}`} sx={{fontWeight: 'bold', color: getStatusColor('certified')}} />
                                <Chip label={`${STATUS.PRECERTIFIED}: ${project.numberOfPendingCertificates}`} sx={{fontWeight: 'bold', color: getStatusColor('pre-certified')}} />
                            </Stack>
                            {
                                project?.numberOfIncompleteCertificates! > 0 &&
                                    ( <Chip label={`incomplet: ${project.numberOfIncompleteCertificates}`} sx={{fontWeight: 'bold', color: getStatusColor('missing-data')}} />)
                            }
                        </Box>
                    </Container>
                )
            }


            {variant === 'empty' &&
                (
                    <Container component={Paper} elevation={5} p={1}>
                        <AddCircleOutlineIcon sx={{fontSize: '5rem', color: 'gray !important'}} />
                    </Container>
                )
            }

            {variant === 'folder' &&
                (
                    <Box
                        component={Paper}
                        elevation={5}
                        p={1}
                        display="flex"
                        flexDirection="column"
                        gap={2}
                        alignItems="center"
                        width="7rem"
                        padding="1rem"
                    >
                        {label}
                        <div style={{width: '3rem', height: '3rem'}}>
                            <FolderIcon  className="color-chart" color="inherit" sx={{fontSize: '3rem'}}/>
                        </div>
                    </Box>
                )
            }
        </>
    )
}