import { Box, Modal } from "@mui/material";
import EditCertificate from "../EditCertificate";
import { ICertificate } from "../../entities/certificates";

interface IProps {
    certificate: ICertificate;
    open: boolean;
    close: () => void;
}


const Component: React.FC<IProps> = ({open, close, certificate}) => {
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box 
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={3}
            sx={{background: 'white', p: 2, pt: 0, height: '60%', width: '40%'}}
        >
            <Box display="flex" flexDirection="column" overflow="scroll" p={2} pt={0} width="70%">
                <EditCertificate certificate={certificate} />
            </Box>

        </Box>
    </Modal>
    )
}

export default Component;