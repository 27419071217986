import React, { useContext, useEffect } from "react";
import { ITemplateFont } from "../../../entities";
import { fetchFonts } from "../../helper";

interface ITemplateFontContext {
    fonts: ITemplateFont
}

export const templateFontsContext= React.createContext<ITemplateFontContext>({} as ITemplateFontContext);

export const TemplateFontProvider: React.FC<any> = ({children}) => {
    const [fonts, setFonts] = React.useState<ITemplateFont>({});

    useEffect(() => {
        fetchFonts()
            .then((f) => f.reduce((curr, acc) => ({...acc, ...curr}), {}))
            .then(setFonts)
    }, []);

    return (
        <templateFontsContext.Provider value={{fonts}}>
            {children}
        </templateFontsContext.Provider>
    );
}

export const useTemplateFonts = () => {
    return useContext(templateFontsContext);
}