import { Box, ButtonBase, CircularProgress, IconButton, LinearProgress, LinearProgressProps, Paper, Typography } from "@mui/material";
import { IProfile } from "../entities";
import { ProjectCard } from "../components/ProjectCard";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch, IState } from "../lib/store";
import { ISession } from "../entities/verification";
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";
import { STATUS, computePercent, formatDate } from "../lib/helper";
import { useEffect, useState } from "react";
import { getVerificationSessions } from "../lib/store/verification/thunks";
import { toast } from "react-toastify";
import LoopIcon from '@mui/icons-material/Loop';

const countPrecertified = (req: Record<string, string>[]): number =>
    req.reduce((acc, curr) => {
        if (curr['status'] == 'pre-certified') {
            return acc + 1;
        }
        return acc;
    }, 0);

export default () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const sessions = useSelector((state: IState) => state.verification.sessions, shallowEqual)!;
    const [loading, setLoading] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [dispatchGet, setDispatchGet] = useState<boolean>(true);
    
    useEffect(() => {
        dispatch(getVerificationSessions()).unwrap()
            .catch(() => {
                toast.error('Une erreur est survenue lors de la verification. veuillez contacter le support si le problème persiste...');
                setIsError(true);
            })
            .finally(() => setLoading(false))
    }, [dispatchGet]);
    
    const onSessionClick = (s: ISession) => {
        if (s.status === "pending") {
            toast.info("Vérification en cours, veuillez rafraichir dans un instant");
            return;
        }
        navigate(s.id!);
    }
    return (
        <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
        >   
            {
                loading ? <CircularProgress /> : (
                    <>
                        {
                            isError ? <Typography color="error">Une erreur est survenue.</Typography> : (
                                [...(sessions || [])].some(s => s.status === "running" || s.status === "pending") ? (
                                    <Box component={Paper} display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" gap={2} elevation={5} p={1}>
                                        <Typography fontWeight="bold">Une verification est en cours, veuillez patienter...</Typography>
                                        <IconButton onClick={() => setDispatchGet(!dispatchGet)} ><LoopIcon /></IconButton>
                                    </Box>
                                ): (
                                    <>
                                        <ButtonBase onClick={() => navigate('import')}>
                                            <ProjectCard variant="empty" />
                                        </ButtonBase>
                                        {
                                            [...(sessions || [])].sort((a, b) => b.createdAt.localeCompare(a.createdAt)).map((s) => (
                                                <ButtonBase key={s.id!} onClick={() => onSessionClick(s)} >
                                                    <Box component={Paper} display="flex" justifyContent="center" flexDirection="column" gap={2} width="8rem" height="8rem" elevation={5} p={1}>
                                                        <Box><span><strong>Début:</strong> {formatDate(s.createdAt)}</span></Box>
                                                            <Box
                                                                sx={{width: '100%', display: 'flex', justifyContent: 'center', gap: 1}}
                                                            >
                                                                <Typography fontWeight="bold" sx={{color: 'green'}} fontSize="18px">{s.nbCertified}</Typography>
                                                                <Typography fontWeight="bold" fontSize="18px">|</Typography>
                                                                <Typography fontWeight="bold" fontSize="18px" sx={{color: 'rgb(180 184 188)'}}>{countPrecertified(s.requests!)}</Typography>
                                                                <Typography fontWeight="bold" fontSize="18px">|</Typography>
                                                                <Typography fontWeight="bold" fontSize="18px" sx={{color: '#f6bab3'}}>{s.total! - countPrecertified(s.requests!)}</Typography>
                                                            </Box>
                                                            <LinearProgressWithLabel value={computePercent(s.nbCertified!, s.total!)} />
                                                    </Box>
                                                </ButtonBase>
                                            ))
                                        }
                                    </>
                                )
                            )
                        }
                    </>
                )
            }
        </Box>
    );
}