import { Box, Button, Paper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { batchVerificationTableDataMapper, computePercent, formatDate, getSessionByID } from "../lib/helper";
import { AppDispatch, IState } from "../lib/store";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";
import { BatchVerificationTable } from "../components/BatchVerificationTable";
import { useState } from "react";
import { ISession } from "../entities/verification";
import { runSession } from "../lib/store/verification/thunks";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

export default () => {
    const navigate = useNavigate();
    const { id='' } = useParams();
    const dispatch = useDispatch<AppDispatch>();
    const session = useSelector((state: IState) => getSessionByID(state.verification.sessions, id), shallowEqual)!;
    const [loading, setLoading] = useState<boolean>(false);

    const run = () => {
        setLoading(true);
        dispatch(runSession(session.id!)).unwrap()
            .then(() => {
                toast.success('Vérification rélancée, veuillez patienter...');
                navigate(-1);
            })
            .catch(() => toast.error('Une erreur est survenue lors de la relance, veuillez contacter le support si le problème persiste...'))
            .finally(() => setLoading(false));
    }
    return (
        <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap={2}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={2}
            >
                <Box component={Paper} sx={{width: '100%', display: 'flex', gap: 1, padding: '10px'}} className="color-chart">
                    <Box flex={11}>
                        <Box flex={2}><span><strong>Début:</strong> {formatDate(session.createdAt)}</span></Box>
                        <Box display="flex" alignItems="center" flex={7}>
                            <LinearProgressWithLabel value={computePercent(session.nbCertified!, session.total!)} />
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <LoadingButton color="inherit" sx={{color: ' rgb(68, 89, 110)'}} variant="outlined" loading={loading} onClick={run}>Relancer</LoadingButton>
                    </Box>
                </Box>
                <BatchVerificationTable rows={batchVerificationTableDataMapper(session.requests!)}/>
            </Box>
        </Box>
    );
}