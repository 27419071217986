import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { LoginCallback } from './components/LoginCallback';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AppDispatch } from './lib/store';
import { me } from './lib/store/auth/thunks';
import AccountVerified from './pages/AccountVerified';
import { CerficatePreview } from './pages/CertificatePreview';
import { Certificates } from './pages/Certificates';
import EditTemplate from './pages/EditTemplate';
import { Login } from './pages/Login';
import { NewOrganization } from './pages/NewOrganization';
import { Project } from './pages/Project';
import { Roles } from './pages/Roles';
import SelectOrganization from './pages/SelectOrganization';
import SendSignupConfirmation from './pages/SendSignupConfirmation';
import { SignUp } from './pages/SignUp';
import { Templates } from './pages/Templates';
import { NewCertificate } from './pages/NewCertificate';
import Dashboard from './pages/Dashboard';
import LayoutV2 from './components/LayoutV2';
import Config from './pages/Config';
import { resetOrganization } from './lib/store/organization/thunks';
import VerificationSessionList from './pages/VerificationSessionList';
import NewVerificationBatch from './pages/NewVerificationBatch';
import VerificationSession from './pages/VerificationSession';
import GlobalResearch from './pages/GlobalResearch';
import CertConfig from './pages/CertConfig';

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const [firstRender, setFirstRender] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  if (firstRender) {
    dispatch(me())
      .unwrap()
      .then(() => {
        if (location.pathname.includes('universities')) {
          dispatch(resetOrganization()).then(() => navigate('/universities'));
        }
      });
  }
  useEffect(() => { setFirstRender(false) }, []);
  
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/oauth_callback" element={<LoginCallback />}/>
        <Route path="/select-organization" element={<SelectOrganization />}/>
        <Route path="/sign-up" element={<SignUp />}/>
        <Route path="/confirm-account" element={<AccountVerified />}/>
        <Route path="/reconfirm-account" element={<SendSignupConfirmation />}/>

        <Route element={<LayoutV2 />}> 
          <Route path='universities'>
            <Route path="" element={<SelectOrganization />}/>
            <Route path="roles" element={<Roles />}/>
            <Route path="config" element={<Config />}/>
            <Route path="templates">
              <Route path="" element={<Templates />} />
              <Route path=":tpID" element={<EditTemplate />} />
            </Route>
            <Route path="new-organization" element={<NewOrganization />}/>
            <Route path="check-certificates">
              <Route path="" element={<VerificationSessionList />} />
              <Route path="import" element={<NewVerificationBatch />} />
              <Route path=":id" element={<VerificationSession />} />
            </Route>
            <Route path="search" element={<GlobalResearch />}/>
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route element={<LayoutV2 />}> 
            <Route path="certifications">
              <Route path="import" element={<NewCertificate />} />
              <Route path="" element={<Project />}/>
              <Route path=":year" element={<Project />}/>
              <Route path=":year/:grade" element={<Project />}/>
              <Route path=":year/:grade/:program" element={<Certificates />}/>
              <Route path=":year/:grade/:program/:hash" element={<CerficatePreview />} />
              <Route path="config" element={<CertConfig />} />
            </Route>
            <Route path="dashboard" element={<Dashboard />}/>
          </Route>
        </Route>
        <Route path="/" element={<Navigate to="/certifications" />}/>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
