import { TextField, Typography, CircularProgress, Button, Box } from "@mui/material"
import { useForm } from "react-hook-form";
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../lib/store";
import { resendSignupNotification } from "../../lib/store/auth/thunks";
import { toast } from "react-toastify";

export default () => {
    const { register, handleSubmit, formState: {errors}, getValues } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [sent, setSent] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();


    const submit = (data: any) => {
        setLoading(true);
        dispatch(resendSignupNotification(data.email))
            .unwrap()
            .then(() => {setSent(true); setLoading(false)})
            .catch(() => {toast.error('une erreur est survenue, veuillez re-essayer. Si le problème persiste contactez nous !'); setLoading(false)})
    }

    return (
        <>
        <form onSubmit={handleSubmit(submit)} style={{display: 'flex', gap: '1rem', width: '100%', justifyContent: 'space-between', alignItems: 'end'}}>
            <TextField style={{marginTop: '1rem', flexGrow: 1}} id="demo-helper-text-misaligned-no-helper" label="email"  {...register('email', {
                required: true,
                pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "adresse email invalide"
                }
            })}/>
            <Button size="medium" style={{width: '15%'}} variant="text" type="submit">
                <SendIcon color="success" sx={{fontSize: '3rem'}}/>
            </Button>
        </form>
        {errors.email && <Typography color="error" sx={{fontSize: '10px', textAlign: 'left', width: '100%'}}>Veuillez entrer une adresse email valide</Typography>}

        {sent && <Typography mt="1rem" sx={{fontSize: '15px', color: '#109CF1', textAlign: 'left', width: '100%'}}>Mail envoyé, veuillez consulter votre boite mail.</Typography>}

        {loading && 
            <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
        }
        </>
    )
}