import { Box, List, Typography, ListItem, ListItemButton, Divider, SwipeableDrawer, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import { IVerificationRequest } from "../../entities/organization";
import VerificationResponseModal from "../VerificationResponseModal";

interface IProps {
    open: boolean;
    close: () => void;
    vRequests: IVerificationRequest[]
}

const Component: React.FC<IProps> = ({open, close, vRequests}) => {
    const VRList: React.FC = () => {
        const [openModal, setOpenModal] = React.useState<boolean>(false);
        const [currVrequest, setCurrVrequest] = React.useState<IVerificationRequest>();
        const [tab, setTab] = useState(0);

        const onTabSelect = (event: React.SyntheticEvent, newValue: number) => {
            setTab(newValue);
        };

        const pendingVreq = React.useMemo(() => {
            return vRequests?.filter(({status}) => status === 'pending');
        }, [vRequests])
    
        return (
            <Box
                sx={{ width: 400 }}
                role="presentation"
            >
                <List>
                <Typography m={2} textAlign="center" fontWeight="bold">Demandes d’authentification</Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={tab} textColor="secondary" indicatorColor="secondary" onChange={onTabSelect} aria-label="basic tabs example" sx={{width: '100%' }} centered variant="fullWidth">
                        <Tab label={`En cours (${pendingVreq?.length})`}/>
                        <Tab label={`Traitées (${vRequests?.length - pendingVreq?.length})`} />
                    </Tabs>
                </Box>            
                {tab === 0 && pendingVreq?.map((vr) => (
                    <div key={vr.id} style={{padding: '10px 2rem'}}>
                        <ListItem  disablePadding className="color-chart" sx={{backgroundColor: 'rgb(209, 223, 230)', borderRadius: '10px'}} onClick={() => {setCurrVrequest(vr); setOpenModal(true)}}>
                            <ListItemButton>
                            <Box 
                                display="flex"
                                flexDirection="column"
                            >
                                <span><strong>Demandeur:</strong> {vr.requesterName}</span>
                                <span><strong>Étudiant(e):</strong> {vr.firstname} {vr.lastname}</span>
                                <span><strong>Diplôme:</strong> {vr.speciality}</span>
                                <span><strong>Matricule:</strong> {vr.matricule}</span>
                                <span><strong>Ine:</strong> {vr.ine}</span>
                            </Box>
                            </ListItemButton>
                        </ListItem>

                        <Divider sx={{marginTop:'1rem', marginBottom: '1rem'}} />
                    </div>
                ))}

                {tab === 1 && vRequests.filter(({status}) => status === 'processed').map((vr) => (
                    <div key={vr.id} style={{padding: '10px 2rem'}}>
                        <ListItem  disablePadding className="color-chart" sx={{backgroundColor: 'rgb(189, 244, 189)', borderRadius: '10px'}}>
                            <ListItemButton>
                            <Box 
                                display="flex"
                                flexDirection="column"
                            >
                                <span><strong>Demandeur:</strong> {vr.requesterName}</span>
                                <span><strong>Étudiant(e):</strong> {vr.firstname} {vr.lastname}</span>
                                <span><strong>Diplôme:</strong> {vr.speciality}</span>
                                <span><strong>Matricule:</strong> {vr.matricule}</span>
                                <span><strong>Ine:</strong> {vr.ine}</span>
                            </Box>
                            </ListItemButton>
                        </ListItem>

                        <Divider sx={{marginTop:'1rem', marginBottom: '1rem'}} />
                    </div>
                ))}
                </List>
                <VerificationResponseModal vReq={currVrequest!} openModal={openModal} closeModal={() => setOpenModal(false)}/>
            </Box>
        );
    }

    return (
        <div>
            <React.Fragment>
                <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={close}
                    onOpen={console.log}
                >
                    <VRList />
                </SwipeableDrawer>
            </React.Fragment>        
        </div>
    );
}

export default Component;