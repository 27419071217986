import React from "react";
import CustomAppBar from "../AppBar";
import { Main } from "../Main";

import IsAuth from "../IsAuth";
import SidebarV2 from "../SidebarV2";


interface IProps {}

const Component: React.FC<IProps> = () => {
    const [broken, setBroken] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);
    const [collapsed, setCollapsed] = React.useState(false);

    return (
        <IsAuth>
            <div style={{ display: 'flex', height: '100%', direction: 'ltr' }}>
                <SidebarV2
                    toggled={toggled}
                    setToggled={setToggled}
                    setBroken={setBroken}
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                />
                <div style={{height: '100%', width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 250px)'}}>
                    <div style={{width: '100%', marginTop: '1rem'}}>
                        <CustomAppBar broken={broken} handleDrawerToggle={() => setToggled(!toggled)}/>
                    </div>
                    <main style={{maxHeight: '90%', overflow: 'scroll'}}>
                        <div style={{ padding: '16px 24px', color: '#44596e' }}>
                            <Main />
                        </div>
                    </main>
                </div>
            </div>
        </IsAuth>
    );
};

export default Component;