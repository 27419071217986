import { Box, Button, Typography } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ICertificateData } from "../../../entities/table";
import { STATUS } from "../../../lib/helper";
import { AppDispatch, IState } from "../../../lib/store";
import { certify, loadCertificates } from "../../../lib/store/projects/thunks";
import { CertifyActionModal } from "../../CertifyActionModal";
import GenerateDiploma from "../../GenerateDiploma";
import AdminComponent from "../../RolesComponent/Admin";
import SearchBar from "../../SearchBar";

interface IProps {
  itemsSelected?: ICertificateData[];
  rows: ICertificateData[];
  unSelect: () => void;
  setLocalRows: React.Dispatch<React.SetStateAction<any[]>>
  variant?: 'A' | 'B'
}

export default (props: IProps) => {
    const { itemsSelected, unSelect, setLocalRows, rows, variant='A' } = props;
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();
    const orgID = useSelector((state: IState) => state.organization.organization.id, shallowEqual)!;

    const closeModal = () => {unSelect(); setOpen(false)};
    const _certify = () => setOpen(true);

    const onSearchTerm = (term: string) => {
      if (term === "") {
        setLocalRows(rows);
        return;
      };
      setLocalRows(rows.filter((r) => r.ine &&  r.ine.toLocaleLowerCase().includes(term.toLocaleLowerCase())))
    }

    const submit = () => 
      dispatch(certify(itemsSelected!.map(({hash}) => hash!))).unwrap()
        .then(() => {dispatch(loadCertificates(orgID)).unwrap()})
        .catch(() => {toast.error('Une erreur est survenue lors de la certification, veuillez contacter le support si le problème persiste...')})
    
    return (
      <>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(itemsSelected?.length! > 0 && {
              bgcolor: (theme) => 'rgba(195, 225, 240, 1)'
              //   alpha('rgb(188, 207, 226)', theme.palette.action.activatedOpacity),
            })
          }}
        >
          {
            variant === 'A' && (itemsSelected?.length! > 0 ? (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  fontWeight="bold"
                >
                  selectionnés: {itemsSelected?.length!}
                </Typography>

                <Box display="flex" gap={3}>
                  {
                    itemsSelected![0].status === STATUS.PRECERTIFIED && (
                      <AdminComponent>
                        <Button onClick={_certify} variant="outlined" color="inherit">
                          Certifier
                        </Button>
                      </AdminComponent>
                    ) 
                  }
                  <AdminComponent> <GenerateDiploma certHashes={itemsSelected!.map(i => i.hash!)} /> </AdminComponent>
                </Box>
              </Box>
            ): (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                mb={3}
                width="100%"
              >
                <Box width="100%">
                  <Typography
                    variant="h6"
                    id="tableTitle"
                    width="100%"
                  >
                    Certificats
                  </Typography>
                </Box>
                <Box width="100%"><SearchBar onSearch={onSearchTerm}/></Box>
              </Box>
            ))
          }

          {
            variant === 'B' && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap={1}
                mb={3}
                width="100%"
              >
                <Box width="100%">
                  <Typography
                    width="100%"
                    textAlign="center"
                  >
                    Veuillez selectionner le certificat ou cliquer sur "CONTINUER" si vous ne le trouvez pas
                  </Typography>
                </Box>
                <Box width="100%" textAlign="center"><SearchBar onSearch={onSearchTerm}/></Box>
              </Box>
            )
          }
        </Toolbar>

        <CertifyActionModal open={open} close={closeModal} certificates={itemsSelected!} submit={submit} />
      </>
    );
}