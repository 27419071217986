import { alpha, Typography, Box, Button } from "@mui/material";
import Toolbar from '@mui/material/Toolbar';
import { ICertificateData } from "../../../entities/table";
import { CertifyActionModal } from "../../CertifyActionModal";
import { useState } from "react";
import AdminComponent from "../../RolesComponent/Admin";
import DownloadCertificates from "../../DownloadCertificates";
import { STATUS } from "../../../lib/helper";
import SearchBar from "../../SearchBar";
import { toast } from "react-toastify";
import { certify } from "../../../lib/store/projects/thunks";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../lib/store";

interface IProps {
  itemsSelected?: ICertificateData[];
  rows: ICertificateData[];
  unSelect: () => void;
  setLocalRows: React.Dispatch<React.SetStateAction<any[]>>
  variant?: 'A' | 'B'
}

export default (props: IProps) => {
    const { itemsSelected, unSelect, setLocalRows, rows, variant='A' } = props;
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const closeModal = () => {unSelect(); setOpen(false)};
    const _certify = () => {
      toast.info('Momentanément désactivé');
      return;
      setOpen(true);
    };

    const submit = () => 
      dispatch(certify(itemsSelected!.map(({hash}) => hash!))).unwrap()
        .then(() => {toast.success('Inscription des certificats dans la blockchain en cours, vous recevez un mail de confirmation une fois terminée.')})
        .catch(() => {toast.error('Une erreur est survenue lors de la certification, veuillez contacter le support si le problème persiste...')})

    return (
      <>
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(itemsSelected?.length! > 0 && {
              bgcolor: (theme) => 'rgba(195, 225, 240, 1)'
            })
          }}
        >
          {
            itemsSelected?.length! > 0 && (
              <Box display="flex" justifyContent="space-between" width="100%">
                <Typography
                  color="inherit"
                  variant="subtitle1"
                  component="div"
                  fontWeight="bold"
                >
                  selectionnés: {itemsSelected?.length!}
                </Typography>

                <Box display="flex" gap={3}>
                  {
                    itemsSelected![0].status === STATUS.PRECERTIFIED && (
                      <AdminComponent>
                        <Button onClick={_certify} variant="outlined" color="inherit">
                          Certifier
                        </Button>
                      </AdminComponent>
                    ) 
                  }
                  <AdminComponent> 
                      <DownloadCertificates
                        certificates={itemsSelected!.map((c) => {
                          const temp = {...c};
                          delete temp.projectID;
                          delete temp.user;
                          delete temp.color;
                          delete temp.date;
                          delete temp.hash;
                          delete temp.id;
                          return temp;
                        })} />
                      </AdminComponent>
                </Box>
              </Box>
            )
          }
        </Toolbar>

        <CertifyActionModal open={open} close={closeModal} certificates={itemsSelected!} submit={submit} />
      </>
    );
}