import organizationSlice, { IOrganizationState } from './organization/index';
import { configureStore } from '@reduxjs/toolkit'
import authSlice, { IAuthState } from './auth';
import projectsSlice, { IProjectState } from './projects';
import verificationSlice, { IVerificateState } from './verification';

export interface IState {
  projects: IProjectState;
  auth: IAuthState;
  organization: IOrganizationState;
  verification: IVerificateState;
}

export const store = configureStore({
  reducer: {
    projects: projectsSlice,
    auth: authSlice,
    organization: organizationSlice,
    verification: verificationSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
