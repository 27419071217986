import { useSelector } from "react-redux";
import { IState } from "../../lib/store";
import { Navigate, Outlet } from "react-router-dom";

interface IProps {
    children: React.ReactElement;
}

const Component: React.FC<IProps> = ({children}) => {
    const isAuth = useSelector((state: IState) => state?.auth?.authenticated);
    const loading = useSelector((state: IState) => state?.auth?.loading);

    if  (!loading && !isAuth) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {children}
        </>
    )
}

export default Component;