import { createTheme, ThemeProvider } from '@mui/material';
import colorTheme from './colors';
import { typographyTheme } from './typography';

interface ProviderProps {
  children: React.ReactNode;
}

export const CustomThemeProvider: React.FC<ProviderProps> = ({ children }) => {
  const MuiTheme = createTheme({ ...colorTheme, ...typographyTheme });
  return <ThemeProvider theme={MuiTheme}>{children}</ThemeProvider>;
};

export * as colors from './colors';
export * as sizes from './sizes';
