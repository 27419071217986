
import { Provider } from 'react-redux';
import {CustomThemeProvider as ThemeProvider} from '../lib/theme'
import {store} from '../lib/store';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TemplateFontProvider } from '../lib/contexts/TemplateFonts';

interface Props {
  children: React.ReactNode;
}
  
  const Providers: React.FC<Props> = ({ children }) => {
    return (
        <ThemeProvider>
          <Provider store={store}>
            <LocalizationProvider adapterLocale='fr'  dateAdapter={AdapterDayjs}>
              <TemplateFontProvider>
                {children}
              </TemplateFontProvider>
            </LocalizationProvider>
          </Provider>
        </ThemeProvider>
    );
  };
  
  export default Providers;