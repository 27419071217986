import { getOrganizations, setOrganization } from './../organization/thunks';
import { ISignInRequest, IProfile, ISignUpRequest, IConfirmAccountRequest } from './../../../entities/profile/index';
import { USER_API } from './../../../config/environment.dev';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from '../../http-client';
import { IState } from '..';
import { LOCALSTORAGE_KEYS } from '../../referentiel';

export const me = createAsyncThunk(
    'auth/me',
    async (_, thunkApi) => {
        try {
            console.log('thunk me')
            const response = await client.get<IProfile>(`${USER_API}/user/profile`, {withCredentials: true});
            await thunkApi.dispatch(getOrganizations(response.data.id!));
            const state = thunkApi.getState() as IState;
            const currentOrgId = localStorage.getItem(LOCALSTORAGE_KEYS.ORG_ID);
            if (currentOrgId) {
                const organization = state.organization.orgs.find(({id}) => id === currentOrgId);
                await thunkApi.dispatch(setOrganization(organization!));
            }
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const signIn = createAsyncThunk(
    'auth/signin',
    async (req: ISignInRequest, {rejectWithValue, dispatch, getState}) => {
        try {
            console.log('signin')
            const response = await client.post<IProfile>(`${USER_API}/user/signin?ivtID=${req.ivtID}`, req, {withCredentials: true});
            localStorage.setItem(LOCALSTORAGE_KEYS.TOKEN, response.data.token!);
            response.data.token = ""; // erase token from profile payload
            if(response.data.id) {
                await dispatch(getOrganizations(response.data.id)); // get organization by user id
                const state = getState() as IState;
                if (state.organization.orgs.length === 1) {
                    await dispatch(setOrganization(state.organization.orgs[0]));
                }
            }
            return response.data;
        } catch(error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const signUp = createAsyncThunk(
    'auth/signup',
    async (req: ISignUpRequest) => {
        try {
            console.log('signup');
            let response: any;
            if (req.ivtID && req.ivtID !== 'null') {
                response = await client.post<IProfile>(`${USER_API}/user/signup?ivtID=${req.ivtID}`, req);
            } else {
                response = await client.post<IProfile>(`${USER_API}/user/signup`, req);
            }
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const confirmAccount = createAsyncThunk(
    'auth/confirm-account',
    async (req: IConfirmAccountRequest, thunkApi) => {
        try {
            console.log('confirm-account')
            const response = await client.post<IProfile>(`${USER_API}/user/confirm-account?id=${req.id}`, req);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const resendSignupNotification = createAsyncThunk(
    'auth/resend-signup-notification',
    async (email: string, thunkApi) => {
        try {
            console.log('confirm-account')
            const response = await client.post<IProfile>(`${USER_API}/user/resend-signup-notification?email=${email}`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);


export const logOut = createAsyncThunk(
    'auth/logout',
    async () => {
        try {
            localStorage.removeItem(LOCALSTORAGE_KEYS.ORG_ID);
            localStorage.removeItem(LOCALSTORAGE_KEYS.TOKEN);
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

interface IUpdatePasswordRequest {
    oldPassword: string;
    newPassword: string;
}

export const updatePassword = createAsyncThunk(
    'auth/update-password',
    async (update: IUpdatePasswordRequest, {rejectWithValue}) => {
        try {
            const response = await client.put<IProfile>(`${USER_API}/user/password`, update);
            return response.data;
        } catch(error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

