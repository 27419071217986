import { Badge, Button, ButtonBase, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import school from '../assets/images/school.svg';
import { IOrganization } from "../entities/organization";
import { AppDispatch, IState } from "../lib/store";
import { setOrganization } from "../lib/store/organization/thunks";
import AddIcon from '@mui/icons-material/Add';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import OperatorComponent from "../components/RolesComponent/Operator";
import { formatDate } from "../lib/helper";
import SearchBar from "../components/SearchBar";
import { useState } from "react";

export default () => {
    const orgs = useSelector((state: IState) => state?.organization?.orgs);
    const loading = useSelector((state: IState) => state?.organization?.loading);

    const [filterOrgs, setFilteredOrgs] = useState<IOrganization[]>(orgs);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const onSelect = (org: IOrganization) =>
        dispatch(setOrganization(org)).unwrap()
            .then(() => {console.log('then'); navigate('/certifications')})
            .catch(() => toast.error('une erreur est survenue, veuillez conctacter le support'));

    const filterByName = (name: string) => {
        if (!name || name === "") {
            setFilteredOrgs(orgs);
            return;
        }
        const _orgs = orgs.filter((v) => v.name.toLowerCase().includes(name.toLowerCase()))
        setFilteredOrgs(_orgs);
    } 
    return (
        <Box height="100%" width="100%" flexDirection="column" display="flex" gap={5} className="color-chart">
            {
                loading ? 
                    (
                        <Box textAlign="center"><CircularProgress sx={{color: '#005e7a'}} /></Box>
                    ) : 
                    (
                        <div 
                            className="select-org"
                            style={{
                                height: '100%',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '1rem'
                            }}> 
                                <OperatorComponent>
                                    <Box display='flex' justifyContent='space-between'>
                                        <Box width='100%'>
                                            <Box flexGrow={1} width='80%'>
                                                <SearchBar onSearch={filterByName} label="Université"  />
                                            </Box>
                                        </Box>
                                        <Button onClick={() => navigate('new-organization')} variant="outlined" color="inherit"> <AddIcon /> Universite</Button>
                                    </Box>
                                </OperatorComponent>
                                <Box
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '1rem'
                        
                                    }}
                                >
                                    {
                                        [...filterOrgs].sort((a, b) => a.name.localeCompare(b.name)).map((org) => (
                                            <ButtonBase onClick={() => onSelect(org)} key={org.id}>
                                                <Box  color="inherit" gap={3} component={Paper} p={2} elevation={2} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} width="14rem" height="14rem">
                                                    <Box width="100%" textAlign="right">
                                                        {
                                                            org.pendingVerifReqCount ? (
                                                                <Badge badgeContent={org.pendingVerifReqCount} color="secondary" invisible={org.pendingVerifReqCount === 0}>
                                                                    <NotificationAddOutlinedIcon sx={{color: '#607489'}} />
                                                                </Badge>
                                                            ) : (
                                                                <div style={{opacity: 0}}>
                                                                    <Badge badgeContent={0} color="secondary">
                                                                        <NotificationAddOutlinedIcon sx={{color: '#607489'}} />
                                                                    </Badge>
                                                                </div>
                                                            )
                                                        }
                                                    </Box>
                                                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                                        <img src={school} width="20%"/>
                                                        <Typography fontWeight="bold" fontSize={'15px'}>{org.name}</Typography>
                                                    </Box>

                                                    <Box display="flex" flexDirection="column">
                                                        <Typography className="color-chart" variant="body2">Date de fin d'import:</Typography>
                                                        <Typography
                                                            className="color-chart"
                                                            variant="body2"
                                                            sx={{color: (new Date(org.importEndDate!)).getTime() >= Date.now() ? 'green' : 'red'}}
                                                            >
                                                                {formatDate(org.importEndDate!)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </ButtonBase>
                                        ))
                                    }


                                </Box>
                        </div>
                    )
            }
        </Box>
    )
}