import { Box, Paper, Typography } from "@mui/material";

const DashBoardCard2: React.FC<{Icon: React.ReactElement, left: {text: string, value: number}, right: {text: string, value: number, color?: string}}> = ({Icon, left, right}) => {
    return (
        <Box
            component={Paper}
            elevation={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding={2}
            gap={2}
            width="40%"
         >
                <Box>{Icon}</Box>
                <Box display="flex" gap={2} width="100%" justifyContent="space-between">
                    <Box textAlign="right">
                        <Typography variant="body2" className="color-chart">{left.text}</Typography>
                        <Typography variant="h6" fontWeight="bold" color="green">{left.value}</Typography>
                    </Box>
                    <Box textAlign="right">
                        <Typography variant="body2" className="color-chart">{right.text}</Typography>
                        <Typography variant="h6" fontWeight="bold" color={right.color || "rgb(237, 108, 2)"}>{right.value}</Typography>
                    </Box>
                </Box>
        </Box>
    )
}


export default DashBoardCard2;
