
import Box from '@mui/material/Box';
import styled from 'styled-components';

export const Container = styled(Box)`
    background-color: #FFFF;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    overflow: scroll;
    width: 90%
`;