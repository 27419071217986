import { Modal, Box, FormControl, TextField, Button, Typography } from "@mui/material"
import { Controller, useForm } from "react-hook-form"
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../lib/store";
import { toast } from "react-toastify";
import { updatePassword } from "../../lib/store/auth/thunks";

interface IProps {
    open: boolean;
    close: () => void
}

const passwordRules = {
    required: {
        value: true,
        message: 'mot de passe obligatoire'
    },
    minLength: {
        value: 8,
        message: 'le mot de passe doit avoir 8 caractères au moins',
    },
}

const Component: React.FC<IProps> = ({open, close}) => {
    const {control, handleSubmit, getValues} = useForm();
    const dispatch = useDispatch<AppDispatch>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const _submit = (form: any) => {
        setLoading(true);
        dispatch(updatePassword({oldPassword: form['oldPassword'], newPassword: form['newPassword']})).unwrap()
            .then(() => {
                setLoading(false);
                toast.success('mot de passe mis à jour avec succès');
                close();
            })
            .catch((error) => {
                setLoading(false);
                setError(error);
                toast.error('Une erreur est survenue, veuillez contacter le support si le problème persiste...')
            })
    }
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box 
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{background: 'white', p: 2, height: '60%', width: '35%', overflow: 'scroll'}}
                className="color-chart"
            >
                <Box width="100%" display="flex" justifyContent="flex-end"><CloseIcon color="error" onClick={close} /></Box>
                <h3 className="color-chart" style={{textAlign: 'center'}}>Modification mot de passe</h3>
                <Box
                    component={'form'}
                    onSubmit={handleSubmit(_submit)}
                    display="flex"
                    justifyContent={'center'}
                    flexDirection="column"
                    gap="2rem"
                    className="color-chart"
                >
                    <FormControl fullWidth>
                        <Controller
                            name="oldPassword"
                            control={control}
                            rules={passwordRules}
                            defaultValue={''}
                            render={({ field, fieldState: {error}}) => 
                                <TextField
                                    type="password"
                                    label='Ancien mot de passe'
                                    id="left"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                                    error={!!error}
                                    helperText={error && <span color="error" style={{fontSize: '10px'}}>{error.message} </span>}                                                       
                                    {...field}
                                />
                            }
                        /> 
                    </FormControl>

                    <FormControl fullWidth>
                        <Controller
                            name="newPassword"
                            control={control}
                            rules={passwordRules}
                            defaultValue={''}
                            render={({ field, fieldState: {error}}) => 
                                <TextField
                                    type="password"
                                    label='Nouveau mot de passe'
                                    id="right"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                                    error={!!error}
                                    helperText={error && <span color="error" style={{fontSize: '10px'}}>{error.message} </span>}                                                       
                                    {...field}
                                />
                            }
                        />
                    </FormControl>

                    <FormControl fullWidth>
                        <Controller
                            name="newPasswordConfirm"
                            control={control}
                            rules={{
                                ...passwordRules,
                                validate: (value: string) => (getValues()['newPassword'] !== value && 'mot de passe non identiques') || undefined
                            }}
                            defaultValue={''}
                            render={({ field, fieldState: {error}}) => 
                                <TextField
                                    type="password"
                                    label='Nouveau mot de passe'
                                    id="right"
                                    size="small"
                                    variant="outlined"
                                    InputProps={{ disableUnderline: true , style: {fontSize: '15px'}}}
                                    error={!!error}
                                    helperText={error && <span color="error" style={{fontSize: '10px'}}>{error.message} </span>}                                                       
                                    {...field}
                                />
                            }
                        />
                    </FormControl>

                    {error && error.includes('mot de passe') && (
                        <Typography variant="body1" color="error" fontWeight="bold" textAlign="center">{error}</Typography>
                    )}

                    <LoadingButton loading={loading} fullWidth type="submit" variant="outlined" color="inherit">Valider</LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}

export default Component;