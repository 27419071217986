import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getOrgByID } from "../lib/helper";
import { AppDispatch, IState } from "../lib/store";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import dayjs from "dayjs";
import { updateImportDate } from "../lib/store/organization/thunks";
import { toast } from "react-toastify";

export default () => {
    const orgs = useSelector((state: IState) => state.organization.orgs, shallowEqual);
    const { control, handleSubmit, getValues } = useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const updateForAllValue = useWatch({ control, name: 'updateForAll', defaultValue: 'false' });

    const submit = (v: any) => {
        setLoading(true);
        dispatch(updateImportDate({
            endDate: (v['importEndDate'] as dayjs.Dayjs).unix(),
            all: v['updateForAll'] === 'true',
            orgs: v['orgs']
        }))
        .unwrap()
        .then(() => toast.success('Date d\'import modifié avec succès'))
        .catch(() => toast.error('Une erreur est survenue, veuillez contacter le support si l\'erreur persiste..'))
        .finally(() => setLoading(false));
    };


    return (
        <Box  p={2} className="color-chart" display="flex" justifyContent="center">
            <Box component={Paper} display="flex" flexDirection="column" gap={3} width="50%" p={4}>
                <Typography variant="h5" color="rgb(68, 89, 110)">Import</Typography>
                <Box component="form" display="flex" flexDirection="column" gap={2} onSubmit={handleSubmit(submit)}>
                    <Controller
                        name={`importEndDate`}
                        control={control}
                        defaultValue={dayjs(Date.now())}
                        render={({ field }) => 
                            <FormControl>
                                <DatePicker
                                    label="Date de fin d'import"
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            </FormControl>
                        }
                    />

                    <Controller
                        name={`updateForAll`}
                        control={control}
                        defaultValue="false"
                        render={({ field }) => 
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Activer pour toutes les universités ?</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={field.value}
                                    onChange={field.onChange}
                                    row
                                    color="inherit"
                                >
                                    <FormControlLabel value={true} control={<Radio />} label="Oui" />
                                    <FormControlLabel value={false} control={<Radio />} label="Non" />
                                </RadioGroup>
                            </FormControl>
                        }
                    />

                    {
                        updateForAllValue === 'false' && (
                            <Controller
                                name={`orgs`}
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) =>
                                    <FormControl sx={{minWidth: 300, maxWidth: 500 }} size="small">
                                        <InputLabel id="org-select-small">Universitiés</InputLabel>
                                        <Select
                                            multiple
                                            labelId="org-select-small"
                                            id="org-select-small"
                                            label="Universités"
                                            value={value}
                                            renderValue={(selected) => 
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value: string) => (
                                                    <Chip key={value} label={getOrgByID(orgs, value).name} />
                                                ))}
                                                </Box>
                                            }
                                            onChange={onChange}
                                        >
                                            {orgs.map(({id, name}) => <MenuItem key={id} value={id}>{name}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                }
                            />
                        )
                    }

                    <LoadingButton type="submit" loading={loading} variant="outlined" color="inherit">Confirmer</LoadingButton>
                </Box>

            </Box>
        </Box>
    );
}