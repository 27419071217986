const colorTheme = {
  palette: {
    primary: {
      main: '#F8485E',
      // 80: '#F47E7E',
      // 60: '#FAA09C',
      light: '#FEC0BC',
      // 20: '#FFDFDC',
      dark: '#C63A4B',
    },
    secondary: {
      dark: '#2B3347',
      main: '#515769',
      light: '#7A7E8C',
    },
    gray: {
      light: '#D1D2D7',
      main: '#A4A7B0',
    },
    white: {
      main: '#FFFFFF',
    },
    black: '#000000',
    pale: '#F6F6F6',
    bg: '#E8E8E8',
    google: {
      main: '#1976d2',
    },
  },
};

export default colorTheme;
