import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import { ICertificateData, Order } from '../../entities/table';

interface IProps {
  rows: any[],
  Head: React.FC<any>;
  Body: React.FC<any>;
  Toolbar: React.FC<any>;
  onRowClick?: (row: any) => void;
  onRowSelect?: (row: any) => void;
  variant?: string;
}

const TableTemplate: React.FC<IProps> = ({rows, Head, Body, Toolbar, onRowSelect, onRowClick, variant}) => {

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof ICertificateData>('status');
  const [selected, setSelected] = React.useState<readonly ICertificateData[]>([]);
  const [currentSelectedStatus, setCurrentSelectedStatus] = React.useState<string>();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [localRows, setLocalRows] = React.useState<any[]>(rows);
  const [currentStatusItemsCount, setCurrentStatusItemsCount] = React.useState<number>(0);

  React.useEffect(() => setLocalRows(rows) , [rows]);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof ICertificateData,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (!currentSelectedStatus) {
        setCurrentStatusItemsCount(localRows.length)
        setSelected(localRows);
        return;
      }
      const newSelecteds = localRows.filter((s) => s.status === currentSelectedStatus);
      setCurrentStatusItemsCount((a) => localRows.reduce((acc, curr) => {
        if (curr.status === currentSelectedStatus) {
          return acc + 1;
        }
        return acc;
      }, 0));
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    setCurrentSelectedStatus(undefined);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: ICertificateData, findIndex: (selected: readonly ICertificateData[], row: ICertificateData) => number) => {
    event.stopPropagation();
    
    const selectedIndex = findIndex(selected, row);
    let newSelected: readonly ICertificateData[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    onRowSelect && onRowSelect(newSelected);
    setCurrentSelectedStatus(newSelected.length > 0 ? newSelected[0].status : undefined);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const isSelected = (hash: string) => selected.findIndex((r) => r.hash === hash) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - localRows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Toolbar 
          itemsSelected={selected}
          unSelect={() => {setSelected([]); setCurrentSelectedStatus(undefined)}}
          rows={rows}
          setLocalRows={setLocalRows}
          variant={variant}
        />  
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <Head
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={currentStatusItemsCount}
            />
            <TableBody>
              <Body 
                rows={localRows}
                order={order}
                orderBy={orderBy}
                page={page}
                rowsPerPage={rowsPerPage}
                handleClick={handleClick}
                isSelected={isSelected}
                currentSelectedStatus={currentSelectedStatus}
                onRowClick={onRowClick}
                variant={variant}
                itemsSelected={selected}
               />
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({count, from, to}) => <span>{from}-{to} de {count}</span>}
          labelRowsPerPage={'Lignes par page'}
        />
      </Paper>
    </Box>
  );
}

export default TableTemplate;