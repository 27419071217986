import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import SingnupReconfirmation from "../components/SingnupReconfirmation.tsx"

export default () => {
    
    return (
        <Box margin="5rem" padding="5rem" component={Paper} elevation={2}>
            <Typography fontWeight="bold">Activation compte</Typography>
            <SingnupReconfirmation />
        </Box>
    )
}