import * as React from 'react';
import Toolbar from './Toolbar';

import { ICertificateData, IRoleData, Order } from '../../entities/table';
import { Head } from './Head';
import { useNavigate } from 'react-router-dom';
import { Body } from './Body';
import Table from '../Table';

interface IProps {
  rows: IRoleData[],
  Toolbar: React.FC;
}

export const RolesTable: React.FC<IProps> = ({rows, Toolbar}) => {
  return <Table Head={Head} Body={Body} rows={rows} Toolbar={Toolbar}/>;
}
