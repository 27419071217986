import { Box, Chip, Modal, Stack, TextField, Typography, ButtonBase, Button } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from "react-hook-form";
import { Container } from "./index.style"
import { IProject } from "../../entities/projects";

interface IProps {
    open: boolean;
    loading: boolean;
    handleCancel: () => void;
    onSubmit: (newProject: IProject, reset: () => void) => void;
}

export const CreateProjectModal: React.FC<IProps> = ({open, handleCancel, onSubmit, loading}) => {
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const submit = (newProject: any) => {
        onSubmit(newProject, reset);
    }
    return (
        <Modal
            style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
            open={open}
            onClose={handleCancel}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Container gap={2} p={4} className="color-chart">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Créer groupe de diplôme
                </Typography>
                <form style={{width: '100%'}} onSubmit={handleSubmit(submit)}>
                    <Box display="flex" flexDirection="column" gap={2} p="0 2rem">
                        <TextField id="demo-helper-text-misaligned-no-helper" placeholder="ex: AGRICULTURE" label="Programe d'étude" {...register('name', {required: true})}/>
                        {errors.name && <Typography color="error" sx={{fontSize: '10px'}}>Veuillez entrer Le programe d'étude</Typography>}
                        <TextField id="demo-helper-text-misaligned-no-helper" label="Description" placeholder="ex: promotion 2006" {...register('description')}/>
                        <Stack direction="row" justifyContent="space-between" p="1rem 7rem">
                            <Button variant="outlined"sx={{right: '1rem'}} onClick={() => {reset(); handleCancel()}} color="error">Annuler</Button>
                            <LoadingButton variant="outlined" type="submit" loading={loading} color="inherit">Créer</LoadingButton>
                        </Stack>
                    </Box>
                </form>

            </Container>
        </Modal>
    )
}