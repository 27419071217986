import { Box, Breadcrumbs, Button, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { ICertificate } from "../../entities/certificates";
import { formatDate, getCertificate } from "../../lib/helper";
import { IState } from "../../lib/store";
import { Container, Link } from "./index.style";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { getTemplateFromId } from "../../lib/Template";

export const Main: React.FC = () => {
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
    const [labels, setLabels] = useState<any[]>([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [currentPath, setCurrentPath] = useState<string[]>([]);
    const matches = useMediaQuery('(min-width:600px)');
    const { year, grade, program, hash } = useParams();
    const [certificate, setCertificate] = useState<ICertificate>();    
    const certificates = useSelector((state: IState) => state.projects.certificates, shallowEqual);
    const templates = useSelector((state: IState) => state?.organization?.orgs[0]?.templates, shallowEqual)!; // refacto
    const authRoles = useSelector((state: IState) => state?.auth?.profile?.roles, shallowEqual);
    const currentOrg = useSelector((state: IState) => state?.organization?.organization, shallowEqual);

    
    useEffect(() => {
        const paths = location.pathname.split('/');
        setCurrentPath([...paths]);

        let label = decodeURIComponent(paths[paths.length - 1]);
        if (hash && !!certificates) {
            const cert = getCertificate(hash, certificates[year!][grade!][program!]);
            setCertificate(cert);
            label = 'retour';
        }

        if (paths[paths.length - 1] === 'new-organization') {
            label = 'nouvelle universitié';
        }

        if (paths[paths.length - 1] === 'config') {
            label = 'configuration';
        }

        if (paths[paths.length - 2] === 'templates') {
            label = getTemplateFromId(label, templates!)?.name! || label;
        }

        const p = [...paths];
        p.pop();

        setBreadcrumbs([<Link key={0} last={"true"} to={p.join('/')}> <ArrowBackIosNewIcon /></Link>])
        setLabels([label]);
    }, [location, certificates]);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                <div>
                    {
                        currentPath &&  (['universities', 'roles', 'dashboard', 'templates', 'config'].every((e) => !currentPath.includes(e))) && currentPath?.length >= 1 && currentPath?.length <= 4 && (
                            <>
                                <Typography variant={matches ? 'h4' : 'h6'} fontWeight={600}>
                                    Diplômes
                                </Typography>
                                <Typography variant="body2">
                                    Créer un groupe de diplôme partageant les critères tels que : la spécialité, la session académique.
                                </Typography>
                                {currentPath[currentPath.length - 1] !== 'import' && (
                                    authRoles?.includes('admin') || (new Date(currentOrg.importEndDate!).getTime()) >= Date.now()  ? (
                                        <Box display="flex" justifyContent="end" width="100%" mb={2}>
                                            <Button onClick={() => navigate('certifications/import')} variant="outlined" color="inherit">Importer</Button>
                                        </Box>
                                    ): (
                                        <Box display="flex" justifyContent="end" width="100%" mb={2}>
                                            <Typography variant="body2" color='red'>La date limite d'importation était le: {formatDate(currentOrg.importEndDate!)} </Typography>
                                        </Box>
                                    )
                                )}
                            </>
                        )
                    }
                    {
                        currentPath?.length === 6 && !!certificate && (
                            <>
                                <Typography variant={matches ? 'h4' : 'h6'} fontWeight={600}>
                                    {certificate?.metadata!['prenoms']} {certificate?.metadata!['nom']}
                                </Typography>
                                <Typography variant="body2">
                                    INE: {certificate?.metadata!['ine']}
                                </Typography>
                            
                            </>
                        )
                    }
                </div>

                {
                    currentPath.length > 2 && (
                        <div style={{display: 'flex', justifyContent: 'left', flexDirection: 'column'}}>
                            <Breadcrumbs
                                separator="<"
                                aria-label="breadcrumb"
                                sx={{marginBottom: '1rem'}}
                            >
                                {breadcrumbs[breadcrumbs.length - 1]}
                            </Breadcrumbs>
                            <Typography>{labels[labels.length - 1]}</Typography>
                        </div>
                    )
                }
            </div>
            <Container>
                <Outlet />
            </Container>
        </>

    )
}