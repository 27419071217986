import { createAsyncThunk } from "@reduxjs/toolkit";
import { IState } from '..';
import { ICreateOrganization, IEnableImportRequest, IInvitation, IInvitationRequest, IOrganization, ITemplate, IUpdateOrgRequest, IVerificationRequest, IVerificationResponse } from '../../../entities/organization';
import { client } from '../../http-client';
import { LOCALSTORAGE_KEYS } from '../../referentiel';
import { getProjects, loadCertificates } from '../projects/thunks';
import { USER_API } from './../../../config/environment.dev';
import { IProfile } from './../../../entities/profile/index';

const wait = (timeout: number) => new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve('done');
    }, timeout);
})

export const createNewOrganization = createAsyncThunk(
    'organization/create',
    async (newOrga: ICreateOrganization, {dispatch}) => {
        try {
            console.log({newOrga})
            const formData = new FormData();
            formData.append('name', newOrga.name);
            formData.append('address', newOrga.address || '');
            formData.append('websiteLink', newOrga.websiteLink || '');
            if (newOrga.logo) {
                formData.append('logo', newOrga.logo);
            }

            await client.post<IOrganization>(`${USER_API}/organization`, formData);
            const data = await dispatch(getOrganizations("")).unwrap();
            localStorage.removeItem(LOCALSTORAGE_KEYS.ORG_ID);
            return data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const setOrganization = createAsyncThunk(
    'organization/set',
    async (org: IOrganization, {dispatch}) => {
        try {
            dispatch(loadCertificates(org.id!));
            // dispatch(getProjects(org.id!)); TODO: remove project references
            dispatch(getVerificationRequests(org.id!));
            localStorage.setItem(LOCALSTORAGE_KEYS.ORG_ID, org.id!);
            return org;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const resetOrganization = createAsyncThunk(
    'organization/reset',
    (_) => {
        localStorage.removeItem(LOCALSTORAGE_KEYS.ORG_ID);
    }
);

export const getVerificationRequests = createAsyncThunk(
    'verificationRequests/get',
    async (orgID: string, _) => {
        try {
            const response = await client.get<IVerificationRequest[]>(`${USER_API}/verification-request/${orgID}`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const processVrequest = createAsyncThunk(
    'organization/processVrequest',
    async (v: Record<string, any>, {getState}) => {
        try {
            const vResponse: IVerificationResponse = {
                valid: v['valid']!,
                reason: v['reason'],
                certificateLocation: v['certificateLocation'],
                processedBy: 'Sidibe Moahmed',
            }
            console.group({vResponse})
            const response = await client.post<IVerificationRequest>(`${USER_API}/verification-request/process/${v['requestID']}`, vResponse);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const getOrganizations = createAsyncThunk(
    'organization/get',
    async (userID: string, {getState}) => {
        try {
            const state = getState() as IState;
            const uID = userID ? userID : state.auth.profile.id;
            const response = await client.get<IOrganization[]>(`${USER_API}/organization?userId=${uID}`);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);


export const inviteMembers = createAsyncThunk(
    'organization/invitemembers',
    async (invitations: IInvitation[], {getState}) => {
        try {
            const state: IState = getState() as IState;
            const payload: IInvitationRequest = {
                data: invitations
            } 
            const response = await client.post<IInvitation[]>(`${USER_API}/organization/invite-user`, payload);
            // console.log('response invitation', response.data);
            return response.data.map((iv) => {
                return {
                    ...iv,
                    firstname: '',
                    lastname: '',
                    roles: [iv.role],
                };
            }) as IProfile[];
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const createNewTemplate = createAsyncThunk(
    'organization/newTemplate',
    async (template: ITemplate, {getState}) => {
        try {
            const state = getState() as IState;
            template.author = `${state.auth.profile.firstname} ${state.auth.profile.lastname}`;
            const response = await client.post<ITemplate>(`${USER_API}/organization/${state.organization.orgs[0].id}/template`, template);
            return {
                ...template,
                id: response.data.id,
                createdAt: Date.now()
            };
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const updateTemplate = createAsyncThunk(
    'organization/updateTemplate',
    async (template: ITemplate, {getState}) => {
        try {
            const state = getState() as IState;
            await client.put<ITemplate>(`${USER_API}/organization/${state.organization.orgs[0].id}/template`, template);
            return {...template, updatedAt: Date.now()};
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const updateImportDate =  createAsyncThunk(
    'organization/updateImportDate',
    async (data: IEnableImportRequest, {getState, dispatch}) => {
        try {
            console.log({data})
            const state = getState() as IState;
            await client.put<ITemplate>(`${USER_API}/organization/import`, data);
            await dispatch(getOrganizations(state.auth.profile.id!));
        } catch(error) {
            return Promise.reject(error);
        }
    }
);

export const updateOrg =  createAsyncThunk(
    'organization/updateOrg',
    async (data: IUpdateOrgRequest, {getState, dispatch}) => {
        try {
            console.log({data});
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('address', data.address || '');
            if (data.logo) {
                formData.append('logo', data.logo);
            }
            const response = await client.put<IOrganization>(`${USER_API}/organization/update/${data.id}`, formData);
            return response.data;
        } catch(error) {
            return Promise.reject(error);
        }
    }
);
