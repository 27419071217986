import { Box, Grid } from "@mui/material"
import Typography from "@mui/material/Typography/Typography"
import avatar from '../../assets/images/blank-profile-pic.png';
import avatar2 from '../../assets/images/sidibe-profile.jpg';

import { IProfile } from "../../entities";
import { ListItemIcon } from "./index.style";
import { Image } from "./index.style";

interface IProps {
    variant?: 'A' | 'B' | 'C';
    user: IProfile;
}

export const UserCard: React.FC<IProps> = ({variant='A', user}) => {
    return (
        <>
            {variant === 'A' && (
                <>
                    <ListItemIcon>
                        <Image src={avatar}  alt="Avatar" />
                    </ListItemIcon>
                    <Box display="flex" flexDirection="column">
                        <Typography sx={{color: '#607489', fontWeight: 'bold'}}>{user.lastname.toLocaleUpperCase()}</Typography>
                        <Typography fontSize="10px">{user.email}</Typography>
                    </Box>
                </>
            )}

            {variant === 'B' && (
                <div style={{width: '100%'}}>
                    <Image src={avatar} alt="Avatar" />
                    <Typography sx={{fontSize: '15px', fontWeight: '400'}}>{`${user.lastname} ${user.firstname}`}</Typography>
                </div>
            )}

            {variant === 'C' && (
                <>
                    <ListItemIcon>
                        <Image src={avatar}  alt="Avatar" />
                    </ListItemIcon>
                    <Box display="flex" flexDirection="column" justifyContent="center !important">
                        <Typography fontSize="10px">{user.email}</Typography>
                    </Box>
                </>
            )}
            
        </>
    )
}